import {
  Box,
  Typography,
  Divider,
  List,
  Collapse,
  IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';
import NavItem from 'src/components/NavItem';
import ROUTES from 'src/constants/routes';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useBreakpoints from 'src/hooks/useBreakpoints';
import CloseIcon from '@material-ui/icons/Close';

const Menu = ({ toggleShowMenu }) => {
  const { smBelow } = useBreakpoints();

  const [openList, setOpenList] = useState({
    ['COF']: location.pathname.includes(ROUTES.CORPSEC_REQ_CHANGE_OFFICER),
    ['CPP']: location.pathname.includes(
      ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS,
    ),
    ['CCP']: location.pathname.includes(
      ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS,
    ),
    ['CSI']: location.pathname.includes(
      ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
    ),
    ['FOR']: location.pathname.includes(
      ROUTES.CORPSEC_REQ_FILLING_OTHER_RESOLUTIONS,
    ),
  });

  const handleListClick = (id) => {
    setOpenList((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const NavItems = {
    cof: {
      textVariant: 'h5',
      selected: false,
      label: 'Change of Officers',
      onClick: () => {
        handleListClick('COF');
      },
      icon: FiberManualRecordIcon,
      iconProps: {
        style: {
          fill: '#7FBA7A',
          fontSize: 15,
        },
        // fontSize: 'small',
      },
      ListItemIconStyle: { justifyContent: ' center' },
      children: openList['COF'] ? (
        <RemoveIcon style={{ color: 'black' }} />
      ) : (
        <AddIcon style={{ color: 'black' }} />
      ),
    },
    cpp: {
      textVariant: 'h5',
      selected: false,
      label: 'Change Personal Particulars',
      icon: FiberManualRecordIcon,
      iconProps: {
        style: {
          fill: '#FFCE73',
          fontSize: 15,
        },
        // fontSize: 'small',
      },
      ListItemIconStyle: { justifyContent: ' center' },
      onClick: () => {
        handleListClick('CPP');
      },
      children: openList['CPP'] ? (
        <RemoveIcon style={{ color: 'black' }} />
      ) : (
        <AddIcon style={{ color: 'black' }} />
      ),
    },
    ccp: {
      textVariant: 'h5',
      selected: false,
      label: 'Change Company Particulars',
      onClick: () => {
        handleListClick('CCP');
      },
      icon: FiberManualRecordIcon,
      iconProps: {
        style: {
          fill: '#3F8CFF',
          fontSize: 15,
        },
        // fontSize: 'small',
      },
      ListItemIconStyle: { justifyContent: ' center' },
      children: openList['CCP'] ? (
        <RemoveIcon style={{ color: 'black' }} />
      ) : (
        <AddIcon style={{ color: 'black' }} />
      ),
    },
    csi: {
      textVariant: 'h5',
      selected: false,
      label: 'Change Share Information',
      onClick: () => {
        handleListClick('CSI');
      },
      icon: FiberManualRecordIcon,
      iconProps: {
        style: {
          fill: '#B695FF',
          fontSize: 15,
        },
        // fontSize: 'small',
      },
      ListItemIconStyle: { justifyContent: ' center' },
      children: openList['CSI'] ? (
        <RemoveIcon style={{ color: 'black' }} />
      ) : (
        <AddIcon style={{ color: 'black' }} />
      ),
    },
    for: {
      textVariant: 'h5',
      selected: false,
      label: 'Filing of Other Resolutions',
      onClick: () => {
        handleListClick('FOR');
      },
      icon: FiberManualRecordIcon,
      iconProps: {
        style: {
          fill: '#A80601',
          fontSize: 15,
        },
        // fontSize: 'small',
      },
      ListItemIconStyle: { justifyContent: ' center' },
      children: openList['FOR'] ? (
        <RemoveIcon style={{ color: 'black' }} />
      ) : (
        <AddIcon style={{ color: 'black' }} />
      ),
    },
    appointmentOfDirector: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname === ROUTES.CORPSEC_REQ_APPT_DIRECTOR,
      secondaryLabel: 'Appointment of Director',
      children: location.pathname === ROUTES.CORPSEC_REQ_APPT_DIRECTOR && (
        <ChevronRightIcon color="primary" />
      ),
      route: ROUTES.CORPSEC_REQ_APPT_DIRECTOR,
    },
    appointmentOfAdmin: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname === ROUTES.CORPSEC_REQ_APPT_ADMIN,
      secondaryLabel: 'Appointment of Administrator',
      children: location.pathname === ROUTES.CORPSEC_REQ_APPT_ADMIN && (
        <ChevronRightIcon color="primary" />
      ),
      route: ROUTES.CORPSEC_REQ_APPT_ADMIN,
    },
    resignationOfDirector: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(ROUTES.CORPSEC_REQ_RESGN_DIRECTOR),
      secondaryLabel: 'Resignation of Director',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQ_RESGN_DIRECTOR,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQ_RESGN_DIRECTOR,
    },
    resignationOfAdmin: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname === ROUTES.CORPSEC_REQ_RESGN_ADMIN,
      secondaryLabel: 'Resignation of Administrator',
      children: location.pathname === ROUTES.CORPSEC_REQ_RESGN_ADMIN && (
        <ChevronRightIcon color="primary" />
      ),
      route: ROUTES.CORPSEC_REQ_RESGN_ADMIN,
    },
    others: {
      textColor: 'textSecondary',
      setSelected: false,
      secondaryLabel: 'Resignation of Administrator',
      children: <ChevronRightIcon color="primary" />,
      // route: ROUTES.CORPSEC,
    },
    changeDirectorParticulars: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(ROUTES.CORPSEC_REQ_DIRECTOR_LIST),
      secondaryLabel: 'Change Director Particulars',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQ_DIRECTOR_LIST,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQ_DIRECTOR_LIST,
    },
    changeShareholderParticulars: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST),
      secondaryLabel: 'Change Shareholder Particulars',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST,
    },
    changeCompanyName: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(
        ROUTES.CORPSEC_REQ_CHANGE_COMPANY_NAME,
      ),
      secondaryLabel: 'Change Company Name',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQ_CHANGE_COMPANY_NAME,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_NAME,
    },
    changeCompanyAddress: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(
        ROUTES.CORPSEC_REQ_CHANGE_COMPANY_ADDRESS,
      ),
      secondaryLabel: 'Change Company Address',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQ_CHANGE_COMPANY_ADDRESS,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_ADDRESS,
    },
    changeCompanyBusinessActivity: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(
        ROUTES.CORPSEC_REQ_CHANGE_BUSINESS_ACTIVITIES,
      ),
      secondaryLabel: 'Change Company Business Activity',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQ_CHANGE_BUSINESS_ACTIVITIES,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQ_CHANGE_BUSINESS_ACTIVITIES,
    },
    changeCompanyFinancialYearEnd: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(
        ROUTES.CORPSEC_REQ_CHANGE_COMPANY_FINANCIAL_YEAR_END,
      ),
      secondaryLabel: 'Change Company Financial Year End',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQ_CHANGE_COMPANY_FINANCIAL_YEAR_END,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_FINANCIAL_YEAR_END,
    },
    allotmentOfShares: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname === ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES,
      secondaryLabel: 'Allotment of Shares',
      children: location.pathname === ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES && (
        <ChevronRightIcon color="primary" />
      ),
      route: ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES,
    },
    transferOfShares: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname === ROUTES.CORPSEC_REQ_TRANSFER_SHARES,
      secondaryLabel: 'Transfer of Shares',
      children: location.pathname === ROUTES.CORPSEC_REQ_TRANSFER_SHARES && (
        <ChevronRightIcon color="primary" />
      ),
      route: ROUTES.CORPSEC_REQ_TRANSFER_SHARES,
    },
    allotmentOfSharesNew: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname === ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES_NEW,
      secondaryLabel: 'Allotment of Shares',
      children: location.pathname ===
        ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES_NEW && (
        <ChevronRightIcon color="primary" />
      ),
      route: ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES_NEW,
    },
    transferOfSharesNew: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname === ROUTES.CORPSEC_REQ_TRANSFER_SHARES_NEW,
      secondaryLabel: 'Transfer of Shares',
      children: location.pathname ===
        ROUTES.CORPSEC_REQ_TRANSFER_SHARES_NEW && (
        <ChevronRightIcon color="primary" />
      ),
      route: ROUTES.CORPSEC_REQ_TRANSFER_SHARES_NEW,
    },
    declareInterimDividend: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(
        ROUTES.CORPSEC_REQUESTS_DECLARE_INTERIM_DIVIDEND,
      ),
      secondaryLabel: 'Declare Interim Dividend',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQUESTS_DECLARE_INTERIM_DIVIDEND,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQUESTS_DECLARE_INTERIM_DIVIDEND,
    },
    openBankAccount: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(
        ROUTES.CORPSEC_REQUESTS_OPEN_BANK_ACCOUNT,
      ),
      secondaryLabel: 'Open Bank Account',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQUESTS_OPEN_BANK_ACCOUNT,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQUESTS_OPEN_BANK_ACCOUNT,
    },
    closeBankAccount: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(
        ROUTES.CORPSEC_REQUESTS_CLOSE_BANK_ACCOUNT,
      ),
      secondaryLabel: 'Close Bank Account',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQUESTS_CLOSE_BANK_ACCOUNT,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQUESTS_CLOSE_BANK_ACCOUNT,
    },
    otherRequest: {
      textColor: 'textSecondary',
      setSelected: false,
      selected: location.pathname.includes(
        ROUTES.CORPSEC_REQUESTS_OTHER_REQUEST,
      ),
      secondaryLabel: 'Other Request',
      children: location.pathname.includes(
        ROUTES.CORPSEC_REQUESTS_OTHER_REQUEST,
      ) && <ChevronRightIcon color="primary" />,
      route: ROUTES.CORPSEC_REQUESTS_OTHER_REQUEST,
    },
  };

  const COFSubList = [
    NavItems.appointmentOfDirector,
    NavItems.appointmentOfAdmin,
    NavItems.resignationOfDirector,
    NavItems.resignationOfAdmin,
    // NavItems.others,
  ];

  const FORSubList = [
    NavItems.declareInterimDividend,
    NavItems.openBankAcc,
    NavItems.closeBankAcc,
    NavItems.otherReq,
  ];

  return (
    <Box py={smBelow ? 1 : 4} flex={2}>
      <Box display="flex" flexDirection="row" alignItems="baseline">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          flex={3}
          pb={4}
          pl={4}
        >
          <Typography variant="h2" style={{ fontSize: '1.5rem' }}>
            Service Request
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Update your company details
          </Typography>
        </Box>
        {smBelow && (
          <IconButton
            size="medium"
            onClick={() => {
              if (toggleShowMenu) toggleShowMenu();
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        )}
      </Box>

      <List
        style={{
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'flex-start',
          width: '100%',
          height: '100%',
        }}
        subheader={<li />}
      >
        <NavItem {...NavItems.cof} />
        <Divider />
        <Collapse in={openList['COF']}>
          <List component="div" disablePadding style={{ width: '100%' }}>
            {COFSubList.map((item) => (
              <NavItem key={item.label} {...item} />
            ))}
          </List>
          <Box py={1} />
        </Collapse>

        <NavItem {...NavItems.cpp} />
        <Divider />
        <Collapse in={openList['CPP']}>
          <List component="div" disablePadding style={{ width: '100%' }}>
            <NavItem {...NavItems.changeDirectorParticulars} />
            <NavItem {...NavItems.changeShareholderParticulars} />
          </List>
          <Box py={1} />
        </Collapse>

        <NavItem {...NavItems.ccp} />
        <Divider />
        <Collapse in={openList['CCP']}>
          <List component="div" disablePadding style={{ width: '100%' }}>
            <NavItem {...NavItems.changeCompanyName} />
            <NavItem {...NavItems.changeCompanyAddress} />
            <NavItem {...NavItems.changeCompanyBusinessActivity} />
            <NavItem {...NavItems.changeCompanyFinancialYearEnd} />
          </List>
          <Box py={1} />
        </Collapse>

        <NavItem {...NavItems.csi} />
        <Divider />
        <Collapse in={openList['CSI']}>
          <List component="div" disablePadding style={{ width: '100%' }}>
            {/* <NavItem {...NavItems.allotmentOfShares} />
            <NavItem {...NavItems.transferOfShares} /> */}
            <NavItem {...NavItems.allotmentOfSharesNew} />
            <NavItem {...NavItems.transferOfSharesNew} />
          </List>
          <Box py={1} />
        </Collapse>

        <NavItem {...NavItems.for} />
        <Divider />
        <Collapse in={openList['FOR']}>
          <List component="div" disablePadding style={{ width: '100%' }}>
            <NavItem {...NavItems.declareInterimDividend} />
            <NavItem {...NavItems.openBankAccount} />
            <NavItem {...NavItems.closeBankAccount} />
            <NavItem {...NavItems.otherRequest} />
          </List>
          <Box py={1} />
        </Collapse>
      </List>
    </Box>
  );
};

export default Menu;
