import { useContext, useState, createContext } from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';

const otpContext = createContext({
  error: '',
  validatingOtp: false,
  submitting: false,
  validateOtpResult: {},
  ValidateOtp: () => Promise.resolve({}),
});

export function useProvideOtp() {
  const [error, setError] = useState();
  const [validatingOtp, setValidatingOtp] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [validateOtpResult, setValidateOtpResult] = useState({});

  const ValidateOtp = async (details = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setValidatingOtp,
      path: `${Config.apiURL}/utils/otp`,
      method: 'post',
      body: details,
    });
    if (res.status === 'success') {
      setValidateOtpResult(res.body.otp);
    }
    return res;
  };

  return {
    error,
    validatingOtp,
    submitting,
    validateOtpResult,
    ValidateOtp,
  };
}

export const useCartContext = () => useContext(otpContext);

export function ProvideOtp({ children }) {
  const otp = useProvideOtp();
  return <otpContext.Provider value={otp}>{children}</otpContext.Provider>;
}
