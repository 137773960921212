import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    border: '2px dashed #808191',
    borderRadius: 8,
    paddingTop: '40px',
    paddingBottom: '40px',
    cursor: 'pointer',
    backgroundColor: '#E4E4E4',
  },
  errorContainer: {
    border: '2px dashed red',
    borderRadius: 8,
    paddingTop: '40px',
    paddingBottom: '40px',
    cursor: 'pointer',
    backgroundColor: '#FFEBE6',
  },
  errorText: { color: '#f44336', marginLeft: '14px', marginRight: '14px' },
}));
