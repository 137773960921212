import React, { useState, useEffect, useRef } from 'react';
import './message.css';
import {
  Typography,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import ContentLayout from 'src/components/ContentLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import MzButton from 'src/components/MzButton';

import CustomTextField from '../components/CustomFormFields/CustomTextField';
// import CustomSelectField from '../components/CustomFormFields/CustomSelectField';
import FormFieldContainer from '../components/CustomFormFields/FormFieldContainer';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 600,
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
    backgroundPosition: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  bgroot: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    zIndex: 0,
    height: '20vh',
  },
  tab: {
    textTransform: 'initial',
    fontSize: 16,
  },
  disabledText: {
    paddingLeft: '5px',
  },
  changeDetails: {
    color: '#888B94',
  },
  gridItem: {
    marginBottom: theme.spacing(2),
  },
  paperContainer: {
    padding: theme.spacing(4),
  },
}));

const fields = {
  fieldName_name: {
    label: 'Name',
    name: 'name',
  },
  fieldName_email: {
    label: 'Email Address',
    name: 'email',
  },
  fieldName_subject: {
    label: 'Subject',
    name: 'subject',
  },
  fieldName_message: {
    label: 'Message',
    name: 'message',
  },
};
const {
  fieldName_name,
  fieldName_email,
  fieldName_subject,
  fieldName_message,
} = fields;

const validationSchema = Yup.object({
  [fieldName_name.name]: Yup.string().required('Name is required'),
  [fieldName_email.name]: Yup.string().required('Email is required'),
  [fieldName_subject.name]: Yup.string().required('Subject is required'),
  [fieldName_message.name]: Yup.string(),
});

const ContactSupport = () => {
  const classes = useStyles();

  const handleSubmit = (values) => {
    console.log('handleSubmit values: ', values);
  };
  return (
    <ContentLayout
      header={
        <>
          <Typography variant="h2">Contact Support</Typography>
          <BreadcrumbsNav pathList={[]} />
        </>
      }
    >
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          borderRadius: '10px',
        }}
        className={classes.paperContainer}
      >
        <Box pb={4}>
          <Box>
            <Typography variant="h3" className={classes.fontWeight}>
              Submit your request
            </Typography>
          </Box>
          <Box>
            Your request will be processed and our support staff will get back
            to you in 24 hours.
          </Box>
        </Box>
        <Box>
          <Formik
            // initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={(valuess) => {
              console.log('valuessvaluess', valuess);
            }}
          >
            {(props) => {
              const { validateForm, values, setTouched, touched } = props;
              return (
                <Form style={{ height: '100%' }}>
                  <Grid container>
                    <Grid container itemxs={12} md={6}>
                      <Grid item xs={12} className={classes.gridItem}>
                        <CustomTextField
                          label={`${fieldName_name.label} *`}
                          name={fieldName_name.name}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.gridItem}>
                        <CustomTextField
                          label={`${fieldName_email.label} *`}
                          name={fieldName_email.name}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.gridItem}>
                        <CustomTextField
                          label={`${fieldName_subject.label} *`}
                          name={fieldName_subject.name}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.gridItem}>
                        <CustomTextField
                          label={`${fieldName_message.label}`}
                          name={fieldName_message.name}
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <MzButton
                          // fullWidth={smBelow ? true : false}
                          // type="submit"
                          title={'Submit'}
                          onClick={() => {
                            validateForm()
                              .then((result) => {
                                console.log('validateForm result', result);
                                setTouched({
                                  ...touched,
                                  ...result,
                                });
                                let canSubmit = false;
                                if (result && Object.keys(result).length == 0) {
                                  canSubmit = true;
                                }
                                if (canSubmit) {
                                  handleSubmit(values, canSubmit);
                                }
                              })
                              .catch((errors) => {
                                console.log('validateForm error', errors);
                                setTouched({ ...touched, ...errors });
                              });
                          }}
                          // loading={requestSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </ContentLayout>
  );
};

export default ContactSupport;
