import React from 'react';
import useStyles from './DescriptionBox.styles';
import { Box, Container, Typography, Paper, Button } from '@material-ui/core';

const ContentBox = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.contentPaper}>
      <Typography style={{ color: '#213053', fontWeight: 900, fontSize: 48 }}>
        Web Builder
      </Typography>
      <Box p={1} />
      <Typography variant="body1" style={{ color: '#213053' }}>
        With lots of unique blocks, you can easily build a page without coding.
        Build your next website within few minutes.
      </Typography>
      <Box p={2} />

      <Button className={classes.contentButton} onClick={() => {}}>
        <Typography variant="h5" style={{ fontWeight: 800 }}>
          Get started now
        </Typography>
      </Button>
    </Paper>
  );
};

const DescriptionBox = ({ img }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container>
        <div
          className={classes.background}
          style={{
            backgroundImage: `url(${img})`,
          }}
        />
        {ContentBox()}
      </Container>
    </Box>
  );
};

export default DescriptionBox;
