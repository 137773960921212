import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const orderContext = createContext({
  orders: [],
  order: {},
  orderError: '',
  orderLoading: false,
  orderSubmitting: false,
  CreateOrder: (order = {}) => Promise.resolve({}),
  ListOrders: () => Promise.resolve({}),
  ReadOrder: (orderID) => Promise.resolve({}),
});

export function useProvideOrder() {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  const [orderError, setOrderError] = useState();
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderSubmitting, setOrderSubmitting] = useState(false);

  const CreateOrder = async (order = {}) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderSubmitting,
      // path: `https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/order`,
      path: `${process.env.REACT_APP_ORDER_URL}/order`,
      method: 'post',
      body: order,
    });
    if (res.status === 'success') {
      console.log('orders list', res.body.orders);
      setOrder(res.body.order || {});
    }
    return res;
  };

  const ListOrders = async (userID) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderLoading,
      // path: `https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/order/user/${userID}`,
      path: `${process.env.REACT_APP_ORDER_URL}/order/user/${userID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      console.log('orders list', res.body.orders);
      setOrders(res.body.orders || []);
    }
    return res;
  };

  const ReadOrder = async (orderID) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderLoading,
      path: `${process.env.REACT_APP_ORDER_URL}/order/${orderID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      console.log('order', res.body.order);
      setOrder(res.body.order);
    }
    return res;
  };

  return {
    orders,
    order,
    orderError,
    orderLoading,
    orderSubmitting,
    CreateOrder,
    ListOrders,
    ReadOrder,
  };
}

export const useOrderContext = () => useContext(orderContext);

export function ProvideOrder({ children }) {
  const order = useProvideOrder();
  return (
    <orderContext.Provider value={order}>{children}</orderContext.Provider>
  );
}
