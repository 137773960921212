import React, { useEffect, useContext } from 'react';
import {
  Typography,
  Box,
  Paper,
  Button,
  Menu,
  MenuItem,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import useStyles from './ManageUser.styles';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MaterialTable from 'material-table';
import TitleLayout from 'src/components/TitleLayout';
import ROUTES from 'src/constants/routes';
import { ConfigContext } from 'src/contexts';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';
import ButtonModal from 'src/components/ButtonModal';

const tempUserList = [
  {
    name: 'Susan Chong',
    role: 'Administrator (Owner)',
    lastlogin: '1 hour ago',
  },
  {
    name: 'Janine Tan',
    role: 'Shareholder',
    lastlogin: '5 hours ago',
  },
  {
    name: 'Peter Lee',
    role: 'Administrator',
    lastlogin: '5 hours ago',
  },
  {
    name: 'Wee Shen Lim',
    role: 'Shareholder',
    lastlogin: '5 hours ago',
  },
];

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const ManageUser = () => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const { company } = useContext(ConfigContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inviteNewUserModal, setInviteNewUserModal] = React.useState(false);
  const [changeRoleModal, setChangeRoleModal] = React.useState(false);
  const [userRole, setUserRole] = React.useState('0');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const mapUserTable = () => {
    return tempUserList.map((user, index) => ({
      name: user.name,
      role: user.role,
      lastlogin: user.lastlogin,
      action: user.action,
    }));
  };

  const UserModal = () => {
    return (
      <>
        <Divider />
        <Box p={4} pb={0}>
          <EnhancedTextField
            fullWidth
            placeholder="test@email.com, test2@email.com"
            shrink
            variant="outlined"
          />
        </Box>
        <Divider />

        <RadioGroup
          value={userRole}
          onChange={(event) => {
            setUserRole(event.target.value);
          }}
        >
          <Box p={2} pl={5} display="flex" alignItems="center">
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="Administrator"
            />
            <Box p={2} />
            <Typography variant="body1">
              The role is for people who need to manage corporate secretary’s
              request.
            </Typography>
          </Box>
          <Divider />

          <Box p={2} pl={5} display="flex" alignItems="center">
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Shareholder"
            />
            <Box p={2} />
            <Typography variant="body1">
              This role is for people who need to view share information, and
              connected accounts, but can’t edit any of them.
            </Typography>
          </Box>
        </RadioGroup>
      </>
    );
  };

  return (
    <>
      <ButtonModal
        isModalOpen={inviteNewUserModal}
        onCloseModal={() => setInviteNewUserModal(false)}
        label="Invite new user"
        subLabel="Enter the email addresses of the users you'd like to invite, and choose the role they should have."
        onPress1={() => setInviteNewUserModal(false)}
        onPress2={() => setInviteNewUserModal(false)}
      >
        <UserModal onCloseModal={() => setInviteNewUserModal(false)} />
      </ButtonModal>
      <ButtonModal
        isModalOpen={changeRoleModal}
        onCloseModal={() => setChangeRoleModal(false)}
        label="Change Role"
        subLabel="Choose the role for the user."
        onPress1={() => setChangeRoleModal(false)}
        onPress2={() => setChangeRoleModal(false)}
      >
        <UserModal onCloseModal={() => setChangeRoleModal(false)} />
      </ButtonModal>

      <TitleLayout
        title="Manage User"
        pathList={[
          { path: 'Home', route: ROUTES.DASHBOARD },
          {
            path: 'Manage User',
            color: 'primary',
          },
        ]}
      >
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
            padding: smBelow ? 20 : 40,
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h4">{company.company_name}</Typography>
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              size="large"
              color="secondary"
              style={{
                textTransform: 'initial',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => setInviteNewUserModal(true)}
            >
              <AddIcon fontSize="small" /> New User
            </Button>
          </Box>
          <Box p={1} />
          <MaterialTable
            localization={{
              header: {
                actions: 'ACTION',
              },
            }}
            options={{
              filtering: false,
              paging: false,
              toolbar: false,
              draggable: false,
              sorting: false,
              headerStyle: {
                backgroundColor: '#FAFAFB',
                paddingRight: 100,
              },
              rowStyle: { backgroundColor: '#FEFEFE' },
              actionsColumnIndex: -1,
            }}
            style={{ width: '100%' }}
            columns={[
              {
                title: 'NAME',
                field: 'name',
                render: (rowData) => (
                  <Box display="flex" alignItems="center">
                    {rowData.name}
                    {rowData.tableData.id === 0 && (
                      <>
                        <Box p={1} />
                        <Box
                          style={{
                            backgroundColor: '#BCE2FF',
                            borderRadius: 8,
                            padding: 5,
                            fontSize: 12,
                          }}
                        >
                          You
                        </Box>
                      </>
                    )}
                  </Box>
                ),
              },
              { title: 'ROLE', field: 'role' },
              {
                title: 'LAST LOGIN',
                field: 'lastlogin',
                render: (rowData) => (
                  <Box display="flex" alignItems="center">
                    {rowData.tableData.id !== 1 && rowData.lastlogin}
                    {rowData.tableData.id === 1 && (
                      <Box
                        style={{
                          backgroundColor: '#92E6AF',
                          borderRadius: 8,
                          padding: 8,
                          fontSize: 12,
                        }}
                      >
                        Invitation sent
                      </Box>
                    )}
                  </Box>
                ),
              },
            ]}
            data={mapUserTable()}
            actions={[
              {
                icon: MoreVertIcon,
                onClick: (event) => {
                  event.preventDefault();
                  setAnchorEl(event.currentTarget);
                },
              },
            ]}
          />
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {}}>
              <VisibilityOutlinedIcon />
              <Box pr={1} />
              View Role
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setChangeRoleModal(true);
              }}
            >
              <AccountBoxOutlinedIcon />
              <Box pr={1} />
              Change Role
            </MenuItem>
            <MenuItem onClick={() => {}} style={{ color: '#DE350B' }}>
              <DeleteOutlinedIcon />
              <Box pr={1} />
              Delete User
            </MenuItem>
          </StyledMenu>
          <Box p={1} />
          <Typography variant="h6" className={classes.fontWeight}>
            {tempUserList.length} Users
          </Typography>
          <Box p={smBelow ? 1 : 5} />
        </Paper>
      </TitleLayout>
    </>
  );
};

export default ManageUser;
