import companySvc from './company';
import axios from './customAxios';

let fileTypes = [];

const BASE_API = `${process.env.REACT_APP_DOCUMENT_URL}`;

const getFileTypes = async () => {
  // if (fileTypes.length > 0) {
  //   return Promise.resolve(fileTypes);
  // }
  const rs = await axios.get(`${BASE_API}/documenttypes`);
  fileTypes = rs.data?.['document-types'] || [];
  return rs.data;
};

const getFiles = async (docType) => {
  const company = companySvc.getCompany();

  // https://r2vorp79i8.execute-api.ap-southeast-1.amazonaws.com/Prod/documents/?table_type=2&primary_id=1&document_type_name=Certificate
  // const rs = await axios.get(`${BASE_API}/documents/?doc_type=${docTypeId}`);
  const rs = await axios.get(
    `${BASE_API}/documentsbytypename/?table_type=2&primary_id=${company.companyId}&document_type_name=${docType}`,
  );
  return rs.data?.document;
};

const searchFiles = async (keyword) => {
  const company = companySvc.getCompany();

  const rs = await axios.get(
    `${BASE_API}documentsbytypename/?table_type=2&primary_id=${company.companyId}&document_type_name=${keyword}`,
  );
  return rs.data?.document;
};

const mapDocType = (docType, docTypes) => {
  return docTypes.find(
    (f) => f.document_type_name.toLowerCase() === docType.toLowerCase(),
  );
};

const getFolders = async () => {
  const fTypes = (await getFileTypes())?.['document-types'] || [];

  return Promise.resolve([
    {
      name: 'A - Bizfile Business Profile',
      type: 'folder',
      hasSubFolders: false,
      ...mapDocType('Biz File', fTypes),
    },
    {
      name: 'B - ACRA Filings',
      type: 'folder',
      hasSubFolders: true,
      ...mapDocType('ACRA Filings', fTypes),
      child: [
        {
          name: '1 - ACRA Filings',
          type: 'folder',
          hasSubFolders: false,
          ...mapDocType('ACRA Filings', fTypes),
        },
        {
          name: '2 - Financial Statements',
          type: 'folder',
          hasSubFolders: false,
          ...mapDocType(
            'RoC Annual - Form AOC-4 - Financial Statements',
            fTypes,
          ),
        },
      ],
    },
    {
      name: 'C - Share Certificates',
      type: 'folder',
      hasSubFolders: false,
      ...mapDocType('Share Certificates', fTypes),
    },
    {
      name: 'D - Registers',
      type: 'folder',
      hasSubFolders: true,
      ...mapDocType('Registers', fTypes),
      child: [
        {
          name: '1 - Register of Directors and CEO',
          type: 'folder',
          ...mapDocType('Share Certificates', fTypes),
          hasSubFolders: false,
        },
        {
          name:
            '2 - Register of Directors and CEO Shareholdings and Other Interest',
          type: 'folder',
          hasSubFolders: false,
        },
        {
          name: '3 - Register of Managers, Secretaries and Auditors',
          type: 'folder',
          hasSubFolders: false,
        },
        {
          name: '4 - Register of Mortgages and Charges',
          type: 'folder',
          hasSubFolders: false,
        },
        {
          name: '5 - Register of Registrable Controllers',
          type: 'folder',
          hasSubFolders: false,
        },
        {
          name: '6 - Register of Nominee Directors',
          type: 'folder',
          hasSubFolders: false,
        },
        {
          name: '7 - Register of Members',
          type: 'folder',
          hasSubFolders: false,
          ...mapDocType('Register of Members', fTypes),
        },
        {
          name: '8 - Register of Debenture',
          type: 'folder',
          hasSubFolders: false,
        },
      ],
    },
    {
      name: 'E - Minutes and Resolutions',
      type: 'folder',
      ...mapDocType('Minutes and Resolutions', fTypes),
      hasSubFolders: true,
      child: [
        {
          name: '7 - Directors meetings and resolutions',
          type: 'folder',
          hasSubFolders: false,
        },
        {
          name: '8 - Shareholders meetings and resolutions',
          type: 'folder',
          hasSubFolders: false,
        },
      ],
    },
    {
      name: 'F - Constitution',
      type: 'folder',
      ...mapDocType('Constitution (CorpSec)', fTypes),
      hasSubFolders: false,
    },
    {
      name: 'G - Miscellaneous',
      type: 'folder',
      ...mapDocType('Miscellaneous', fTypes),
      hasSubFolders: false,
    },
    {
      name: 'H - Scanned Mail',
      type: 'folder',
      ...mapDocType('Scanned Mail', fTypes),
      hasSubFolders: false,
    },
  ]);
};

const documentSvc = { getFolders, searchFiles, getFiles };

export default documentSvc;
