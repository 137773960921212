import authSvc from './authentication';
import axios from './customAxios';

console.log('process.env.REACT_APP_ORDER_URL', process.env.REACT_APP_ORDER_URL);
const addToCart = async (item) => {
  const auth = authSvc.getAuthData();
  const { data } = await axios.post(
    `${process.env.REACT_APP_ORDER_URL}/cart/additem`,
    {
      ...item,
      user_id: auth.user.user_id,
    },
  );

  if (data && !data.is_success) throw Error(data?.message);
};

const cartSvc = { addToCart };

export default cartSvc;
