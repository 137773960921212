import { AppBar, Box, Container, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AuthLayout from 'src/components/AuthLayout';
import {
  CorpsecDashboard,
  CorpsecDocuments,
  CorpsecNameBox,
  CorpsecProfile,
  CorpsecRequests,
  CorpsecRequestsApptAdmin,
  CorpsecRequestsApptDirector,
  CorpsecRequestsChangeCompanyParticulars,
  CorpsecRequestsChangeCompanyName,
  CorpsecRequestsChangeCompanyFinancialYearEnd,
  CorpsecRequestsChangeCompanyAddress,
  CorpsecRequestsChangeBusinessActivities,
  CorpsecRequestsChangeShareInformation,
  CorpsecRequestsAllotmentShares,
  CorpsecRequestsTransferShares,
  CorpsecRequestsChangeOtherResolutions,
  CorpsecRequestsDeclareInterimDividend,
  CorpsecRequestsOpenBankAccount,
  CorpsecRequestsCloseBankAccount,
  CorpsecRequestsOtherRequest,
  CorpsecRequestsChangeDirectorParticulars,
  CorpsecRequestsChangeOfficers,
  CorpsecRequestsChangePersonalParticulars,
  CorpsecRequestsChangeShareholderParticulars,
  CorpsecRequestsDirectorList,
  CorpsecRequestsResgnAdmin,
  CorpsecRequestsResgnDirector,
  CorpsecRequestsShareholderList,
  CorpsecSidebar,
  CorpsecTaskList,
  CorpsecBoardRoom,
  CorpsecCapTable,
} from 'src/components/Corpsec';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';

const Corpsec = () => {
  const location = useLocation();
  const [namebox, showNamebox] = useState(true);
  const params = useParams();
  const { mdBelow } = useBreakpoints();

  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box display="flex" height="calc(100% - 64px)" flexDirection="column">
        <Box
          display={mdBelow ? 'initial' : 'flex'}
          flexDirection="row"
          flexGrow={1}
        >
          <Container maxWidth="xl">
            <CorpsecDashboard />
          </Container>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Corpsec;
