import authSvc from './authentication';
import axios from './customAxios';

const products = async () => {
  const auth = authSvc.getAuthData();
  const { data } = await axios.get(
    `${process.env.REACT_APP_ORDER_URL}/products`,
  );

  if (data && !data.is_success) throw Error(data?.message);

  return data?.products;
};

const productSvc = { products };

export default productSvc;
