import React from 'react';
import { TextField, Typography, Box } from '@material-ui/core';
import FileDropzone from 'src/components/FileDropzone';
import { useFormikContext } from 'formik';
import { Field } from 'formik';

const FormFileDropzone = ({
  name,
  containerWidth = '100%',
  styles,
  labelProps = {},
  ...props
}) => {
  const { setFieldValue, values } = useFormikContext();
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <>
          <Box
            display="flex"
            flexDirection="column"
            width={containerWidth}
            style={styles}
          >
            <Typography
              variant="body2"
              align="left"
              style={{ paddingLeft: 5 }}
              {...labelProps}
            >
              {props.labelText}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              style={{
                paddingLeft: 5,
                fontStyle: 'italic',
                color: '#808080',
              }}
            >
              {props.optional && '(optional)'}
            </Typography>

            <FileDropzone
              {...field}
              {...props}
              files={field.value || []}
              setFiles={(files = [], removeFile = false) => {
                if (
                  files.length === 0 &&
                  field?.value.length > 0 &&
                  !removeFile
                ) {
                  files = field.value;
                }

                setFieldValue(field.name, files);
              }}
              error={meta.touched && !!meta.error}
              helperText={
                meta.touched && meta.error ? meta.error : props.helperText || ''
              }
            />
          </Box>
        </>
      )}
    </Field>
  );
};

export default FormFileDropzone;
