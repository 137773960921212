import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Divider,
  ButtonBase,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import useStyles from './TitleLayout.styles';
import { ConfigContext } from 'src/contexts';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BusinessIcon from '@material-ui/icons/Business';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ROUTES from '../../constants/routes';

const TitleLayout = ({ title, pathList, children }) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const {
    cartList,
    confirmationDialog,
    company,
    companyList,
    setCompany,
    companyLoading,
  } = useContext(ConfigContext);
  const [openCompany, setOpenCompany] = useState(false);
  const [othersCompanyList, setOthersCompanyList] = useState([]);
  const [collaborationCompanyList, setCollaborationCompanyList] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const othersList = companyList.filter(
      (c) => c.company_id !== company.company_id,
    );
    setOthersCompanyList(othersList);
  }, [company]);

  function truncate(str, num) {
    if (str) return str.length > num ? str.substring(0, num) + '...' : str;
  }

  const handleCloseCompany = () => {
    setOpenCompany(false);
  };

  return (
    <LoadingOverlay
      active={companyLoading}
      spinner={<ClipLoader color="white" />}
      styles={{
        wrapper: {
          overflow: companyLoading ? 'hidden' : 'initial',
        },
      }}
    >
      {companyLoading ? (
        <div
          style={{
            width: '100%',
          }}
        >
          <EnhancedSkeleton
            layout={[
              {
                loaders: [{ variant: 'rect', height: 100, width: '100%' }],
                repeat: 5,
                mt: 4.25,
              },
            ]}
          />
        </div>
      ) : (
        <Box
          px={4}
          py={2}
          display="flex"
          flexDirection="column"
          style={{ minHeight: '85%' }}
        >
          <Box
            display="flex"
            flexDirection={smBelow ? 'column' : 'row'}
            width="100%"
            pb={2}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              pb={2}
            >
              <Typography variant="h2">{title}</Typography>
              <BreadcrumbsNav pathList={pathList} />
            </Box>
            <div style={{ flexGrow: 1 }} />

            <Box>
              <Typography variant="body2">Selected Company:</Typography>

              <Box
                display="flex"
                alignItems="center"
                className={classes.companyBox}
              >
                <BusinessIcon />
                <Box pr={1} />
                <Select
                  value={company.company_id}
                  open={openCompany}
                  onClose={handleCloseCompany}
                  onOpen={() => setOpenCompany(true)}
                  IconComponent={ExpandMore}
                  disableUnderline
                  className={classes.companyselect}
                >
                  <Typography
                    variant="caption"
                    className={classes.companyTitle}
                  >
                    Current Company
                  </Typography>
                  <MenuItem
                    className={classes.companyItem}
                    value={company.company_id}
                  >
                    <Typography variant="h6" noWrap>
                      {company.company_name}
                    </Typography>
                  </MenuItem>

                  {othersCompanyList.length > 0 && (
                    <>
                      <Divider variant="middle" />
                      <Typography
                        variant="caption"
                        className={classes.companyTitle}
                      >
                        Others
                      </Typography>
                      {othersCompanyList.map((item, index) => (
                        <MenuItem
                          key={item.company_name}
                          value={item.company_id}
                          className={classes.companyItem}
                          onClick={() => {
                            setCompany(item.company_id);
                            handleCloseCompany();
                          }}
                        >
                          <Typography variant="h6" noWrap>
                            {truncate(item.company_name, 25)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </>
                  )}

                  {collaborationCompanyList.length > 0 && (
                    <>
                      <Divider variant="middle" />
                      <Typography
                        variant="caption"
                        className={classes.companyTitle}
                      >
                        Collaboration
                      </Typography>
                      {collaborationCompanyList.map((item, index) => (
                        <MenuItem
                          key={item.company_name}
                          value={item.company_id}
                          className={classes.companyItem}
                          onClick={() => {
                            setCompany(item.company_id);
                            handleCloseCompany();
                          }}
                        >
                          <Typography variant="h6" noWrap>
                            {truncate(item.company_name, 25)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </>
                  )}

                  <Divider variant="middle" />
                  <ButtonBase
                    onClick={() => {
                      history.push(ROUTES.CORPSEC_COMPANY_ACCOUNT_SETTINGS);
                    }}
                  >
                    <Typography variant="h6" className={classes.addNewCompany}>
                      + Add New Company Account
                    </Typography>
                  </ButtonBase>
                </Select>
              </Box>
            </Box>
          </Box>
          {children}
        </Box>
      )}
    </LoadingOverlay>
  );
};

export default TitleLayout;
