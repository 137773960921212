import React, { useState, useContext } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Link,
  Grid,
  TextField,
  SvgIcon,
  Card,
  CardContent,
} from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FacebookIcon from '@material-ui/icons/Facebook';
import AppleIcon from '@material-ui/icons/Apple';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import red from '@material-ui/core/colors/red';

import { ReactComponent as GoogleIcon } from '../../../assets/signin/google-icon.svg';
import ROUTES from '../../../constants/routes';
import authSvc from '../../../services/authentication';
import ProgressLoader from '../../Progress';
import { ConfigContext } from 'src/contexts';
import companySvc from 'src/services/company';
import FormCheckbox from '../../FormCheckbox/FormCheckbox';
import useBreakpoints from 'src/hooks/useBreakpoints';

import CustomTextField from '../../CustomFormFields/CustomTextField';
import CustomPasswordField from '../../CustomFormFields/CustomPasswordField';

const fieldName_email = 'email';
const fieldName_password = 'password';

const initialValues = {
  email: '',
  password: '',
};

function SignInForm(props) {
  const history = useHistory();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(ConfigContext);
  const { smBelow } = useBreakpoints();
  const theme = useTheme();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
    rememberMe: Yup.bool(),
  });

  const formikOnSubmit = async (values, actions) => {
    //     setLoading(true);
    //     setMsg('');
    // <<<<<<< HEAD
    //     await authSvc.signin(values).then(
    //       (rs) => {
    //         // if (rs.is_success === true) {
    //         setUser(rs.user);
    //         // }
    //         companySvc.companiesAndShares().then((response) => {
    //           if (Object.keys(response).length !== 0) {
    //             companySvc.setCompany({
    //               companyName: response[0].company_name,
    //               companyId: response[0].company_id,
    //               uen: response[0].company_registration_no,
    //             });
    //             console.log('comps', companySvc.getCompany());
    //           }
    //         });
    // =======

    //     let body = {
    //       username: values[fieldName_email],
    //       [fieldName_password]: values[fieldName_password],
    //     };
    //     await authSvc
    //       .signin2(body)
    //       .then((result) => {
    //         setUser(result.user);
    //         companySvc
    //           .companiesAndShares()
    //           .then((response) => {
    //             if (response && response.length > 0) {
    //               companySvc.setCompany({
    //                 companyName: response[0].company_name,
    //                 companyId: response[0].company_id,
    //                 uen: response[0].company_registration_no,
    //               });
    //             }
    //             // console.log('comps', companySvc.getCompany());
    //             setLoading(false);
    //             history.push(ROUTES.DASHBOARD);
    //           })
    //           .catch((error) => {
    //             setMsg(error.message);
    //             setLoading(false);
    //             history.push(ROUTES.DASHBOARD);
    //           });
    //         // setLoading(false);
    //         // history.push(ROUTES.DASHBOARD);
    //       })
    //       .catch((error) => {
    //         console.log('error', error);
    //         setMsg(error?.error?.message);
    // >>>>>>> a7563ce06f9cdfbe07262f02d85e5d2e64ec4ec9
    //         setLoading(false);
    //       });
    setLoading(true);
    setMsg('');

    let body = {
      username: values[fieldName_email],
      [fieldName_password]: values[fieldName_password],
    };
    await authSvc
      .signin2(body)
      .then((result) => {
        setUser(result.user);
        companySvc
          .companiesAndShares()
          .then((response) => {
            if (response && response.length > 0) {
              companySvc.setCompany({
                companyName: response[0].company_name,
                companyId: response[0].company_id,
                uen: response[0].company_registration_no,
              });
            }
            // console.log('comps', companySvc.getCompany());
            setLoading(false);
            history.push(ROUTES.DASHBOARD);
          })
          .catch((error) => {
            setMsg(error.message);
            setLoading(false);
            history.push(ROUTES.DASHBOARD);
          });
        // setLoading(false);
        // history.push(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        console.log('error', error);
        setMsg(error?.error?.message);
        setLoading(false);
      });

    // await authSvc.signin(values).then(
    //   (rs) => {
    //     // if (rs.is_success === true) {
    //     setUser(rs.user);
    //     // }
    //     companySvc.companiesAndShares().then((response) => {
    //       console.log('response', response[0].company_name);
    //       companySvc.setCompany({
    //         companyName: response[0].company_name,
    //         companyId: response[0].company_id,
    //         uen: response[0].company_registration_no,
    //       });
    //       console.log('comps', companySvc.getCompany());
    //     });
    //     setLoading(false);

    //     history.push(ROUTES.DASHBOARD);
    //   },
    //   (err) => {
    //     setMsg(err.message);
    //     setLoading(false);
    //   },
    // );
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: 16,
        border: smBelow ? 'none' : '',
        width: smBelow ? '100%' : '400px',
        maxWidth: '100%',
      }}
    >
      <CardContent>
        <Box fontWeight="700" fontSize="30px" textAlign="center">
          Welcome Back
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Typography color="textSecondary" variant="body2">
            New to Meyzer360?&nbsp;
            <NavLink color="primary" to={'/signup'}>
              <Typography color="primary" variant="inherit">
                Register an account
              </Typography>
            </NavLink>
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form style={{ width: '100%' }}>
              <Box mb={2}>
                <CustomTextField name={fieldName_email} label="Email address" />
              </Box>
              <Box mb={1}>
                <CustomPasswordField
                  label={
                    <>
                      <span>Password</span>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '4px',
                          marginLeft: 'auto',
                        }}
                      >
                        <Link href="#" color="primary">
                          Forgot password?
                        </Link>
                      </Typography>
                    </>
                  }
                  name={fieldName_password}
                />
              </Box>
              <Box mb={1}>
                <Field name="rememberMe">
                  {({ field, meta }) => (
                    <FormCheckbox
                      name="rememberMe"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      label={
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">
                            Keep me logged in
                          </Typography>
                        </Box>
                      }
                      {...field}
                    />
                  )}
                </Field>
              </Box>
              <Button
                variant="contained"
                color="primary"
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                // variant="contained"
                style={{
                  color: '#FFFFFF',
                  textTransform: 'initial',
                  fontWeight: 700,
                }}
                size="large"
                fullWidth={true}
                disableElevation
              >
                Sign in
                {/* {loading ? <ProgressLoader loading={true} /> : 'Sign in'} */}
              </Button>
              {msg && <p style={{ color: red[500] }}>{msg}</p>}
              <Box display="flex" justifyContent="center" mt={loading ? 2 : 0}>
                <ProgressLoader loading={loading} />
              </Box>
            </Form>
          )}
        </Formik>
        <Box display="flex" alignItems="center" justifyContent="center" p={3}>
          <Typography color="textSecondary" variant="body2">
            Or sign in with
          </Typography>
        </Box>
        {/* <Box> */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              style={{
                backgroundColor: '#4267B2',
                color: '#ffffff',
              }}
              fullWidth={true}
              size="large"
              disableElevation
            >
              <FacebookIcon />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                backgroundColor: '#F14336',
                color: '#fff',
              }}
              fullWidth={true}
              size="large"
              disableElevation
            >
              <SvgIcon style={{ color: '#fff' }}>
                <GoogleIcon />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                backgroundColor: '#000000',
                color: '#ffffff',
              }}
              fullWidth={true}
              size="large"
              disableElevation
            >
              <AppleIcon />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

SignInForm.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SignInForm;
