import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ActionList from 'src/components/ActionList';
import { useProvideUser } from 'src/hooks/useUsers';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import { ConfigContext } from 'src/contexts';

const ResgnDirector = ({ toggleShowMenu }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const { ListUserByCompanyMultipleRole, users } = useProvideUser();
  const { company } = useContext(ConfigContext);

  const getStarted = {
    one: {
      content: [
        'Remove an Active Director of your business',
        'If the resigning director is a local director, it is important to note that there MUST be at least ONE local director remaining on the board.',
      ],
    },
    two: {
      content: [
        'The Director’s resignation date and reason for resigning.',
        'Submit a copy of the Director’s resignation letter to Meyzer360.',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['Basic Plan: SGD50', 'Unlimited Plan: Free'],
    },
    five: {
      content: [
        'Time to fill form: < 2 minutes.',
        'Average processing time: 3-5 days',
      ],
    },
  };

  useEffect(() => {
    if (company.company_id) {
      ListUserByCompanyMultipleRole(company.company_id, 5, 2, 1, 14);
    }
  }, [company.company_id]);

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Resignation of Director'}
              {step === 2 && 'Select Director to Resign'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Request', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change of Officers',
                  route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
                },
                {
                  path: 'Resignation of Director',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <ActionList
            itemList={users}
            buttonLabel="Resign"
            onClick={(directorId) => {
              history.push(ROUTES.CORPSEC_REQ_RESGN_DIRECTOR_INFO(directorId));
            }}
            onClickBackButton={() => {
              history.push(ROUTES.CORPSEC_REQ_CHANGE_OFFICER);
            }}
          />
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default ResgnDirector;
