import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Typography,
  Box,
  InputAdornment,
  Tabs,
  Tab,
  Divider,
  Paper,
  ButtonBase,
} from '@material-ui/core';
import { StringMasking } from 'src/utils/string';
import { useTheme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import Stepper from 'src/components/Stepper';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideUser } from 'src/hooks/useUsers';
import { useProvideUserAddresses } from 'src/hooks/useUserAddresses';
import { useProvideData } from 'src/hooks/useData';
import { useProvideRequest } from 'src/hooks/useRequests';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideProduct } from 'src/hooks/useProduct';
import { ConfigContext } from 'src/contexts';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { EnhancedIcon } from 'src/components/Enhanced';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import CheckboxField from 'src/components/CheckboxField';
import OtpAlert from 'src/components/OtpAlert';

const tempSteps = [
  {
    id: 1,
    label: 'Change Address',
  },
  {
    id: 2,
    label: 'Change Contact Info',
  },
  {
    id: 3,
    label: 'Change Residency Status',
  },
  {
    id: 4,
    label: 'Change Name',
  },
];

const ChangeDirectorParticulars = ({ toggleShowMenu }) => {
  const history = useHistory();
  const params = useParams();
  const theme = useTheme();
  const { countries, idTypes, ListIDType, ListCountries } = useProvideData();
  const { user, userContacts, GetUserContact, GetUserByID } = useProvideUser();
  const {
    CreateUpdateParticularsApplication,
    requestError,
  } = useProvideRequest();
  const { userAddressess, ListUserAddressess } = useProvideUserAddresses();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const {
    addCart,
    user: loggedInUser,
    company,
    setConfirmationDialog,
  } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();
  const [submitting, setSubmitting] = useState(false);
  const buttonClasses = useButtonStyle();
  const [tabValue, setTabValue] = useState(0);
  const [step, setStep] = useState(0);
  const { smBelow } = useBreakpoints();
  const [countryCode, setCountryCode] = useState('SG');
  const [checkedAddress, setCheckedAddress] = useState(false);
  const [checkedMobile, setCheckedMobile] = useState(false);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [checkedNationality, setCheckedNationality] = useState(false);
  const [checkedName, setCheckedName] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const stepformRef = useRef(null);

  useEffect(() => {
    GetUserByID(params.id);
    GetUserContact(params.id);
    ListUserAddressess(params.id);
    ListIDType();
    ListCountries();
    ReadProduct(5);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const step1validationSchema = Yup.object({
    address_line_1: Yup.string().when('checkedAddress', {
      is: () => checkedAddress,
      then: Yup.string().required('Address Line 1 is required'),
    }),
    postal_code: Yup.string().when('checkedAddress', {
      is: () => checkedAddress,
      then: Yup.string().required('Postal Code is required'),
    }),
    country_id: Yup.string().when('checkedAddress', {
      is: () => checkedAddress,
      then: Yup.string().required('Country is required'),
    }),
    proofOfIdentity: Yup.array().when('checkedAddress', {
      is: () => checkedAddress,
      then: Yup.array().min(1, 'Proof of Residency is required'),
    }),
  });

  const step2validationSchema = Yup.object({
    email: Yup.string().when('checkedEmail', {
      is: () => checkedEmail,
      then: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    }),
    mobile_number: Yup.string().when('checkedMobile', {
      is: () => checkedMobile,
      then: Yup.string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .required('Mobile Number is required'),
    }),
  });

  const step3validationSchema = Yup.object({});

  const step4validationSchema = Yup.object({
    first_name: Yup.string().when('checkedName', {
      is: () => checkedName,
      then: Yup.string().required('First Name is required'),
    }),
    last_name: Yup.string().when('checkedName', {
      is: () => checkedName,
      then: Yup.string().required('Last Name is required'),
    }),
  });

  const getBusinessAddress = () => {
    return userAddressess.find(
      (address) => address.address_type_name === 'Business',
    );
  };

  const getContactEmail = () => {
    return userContacts.find(
      (contact) => contact.contact_type_name === 'email',
    );
  };

  const getContactMobile1 = () => {
    return userContacts.find(
      (contact) => contact.contact_type_name === 'mobile1',
    );
  };

  const getDefaultCountry = (countryID) => {
    return countries.find((country) => country.country_id === countryID);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const stepOne = () => {
    return (
      <Formik
        initialValues={{
          postal_code: '',
          floor_unit_no: '',
          address_line_1: '',
          address_line_2: '',
          address_line_3: '',
          country_id: '',
          proofOfIdentity: [],
        }}
        enableReinitialize
        onSubmit={step1formikOnSubmit}
        validationSchema={step1validationSchema}
        innerRef={stepformRef}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Tabs
              style={{
                borderBottom: '0.01rem solid #E4E4E4',
                paddingLeft: theme.spacing(smBelow ? 3 : 12),
              }}
              indicatorColor="primary"
              textColor="primary"
              value={tabValue}
              onChange={handleTabChange}
            >
              <Tab
                label="Local"
                style={{
                  textTransform: 'initial',
                  fontSize: 16,
                }}
              />
              <Tab
                label="Foreigner"
                style={{
                  textTransform: 'initial',
                  fontSize: 16,
                }}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Box p={3}>
                {!!userAddressess[0] && (
                  <>
                    <CheckboxField
                      checkboxLabel="Change Address"
                      checked={checkedAddress}
                      onCheckedChange={(event) =>
                        setCheckedAddress(event.target.checked)
                      }
                      checkboxList={[
                        {
                          currentLabel: 'CURRENT FLOOR / UNIT NO',
                          currentValue: userAddressess[0].floor_unit_no,
                          child: (
                            <FormTextField
                              name="floor_unit_no"
                              labelText="NEW FLOOR / UNIT NO"
                              placeholder="#90-9"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT ADDRESS LINE 1',
                          currentValue: userAddressess[0].address_line_1,
                          child: (
                            <FormTextField
                              name="address_line_1"
                              labelText="NEW ADDRESS LINE 1"
                              placeholder="Orchard Road"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT ADDRESS LINE 2',
                          currentValue: userAddressess[0].address_line_2,
                          child: (
                            <FormTextField
                              name="address_line_2"
                              labelText="NEW ADDRESS LINE 2"
                              placeholder="Ngee Ann City Tower A"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT ADDRESS LINE 3',
                          currentValue: userAddressess[0].address_line_3,
                          child: (
                            <FormTextField
                              name="address_line_3"
                              labelText="NEW ADDRESS LINE 3"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT POSTCODE',
                          currentValue: userAddressess[0].postal_code,
                          child: (
                            <FormTextField
                              name="postal_code"
                              labelText="NEW POSTCODE"
                              placeholder="542902"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT COUNTRY',
                          currentValue: userAddressess[0].country_name,
                          child: (
                            <Box
                              display="flex"
                              alignItems="flex-start"
                              flexDirection="column"
                              width={'50%'}
                            >
                              <FormDropdown
                                autoCompleteStyle={{ width: '100%' }}
                                name="country_id"
                                onChange={(event, newValue) => {
                                  props.setFieldValue(
                                    'country_id',
                                    newValue?.value,
                                  );
                                }}
                                optionList={countries}
                                labelText="NEW COUNTRY"
                                placeholder="AUSTRALIA"
                                shrink
                                variant="outlined"
                                defaultValue={getDefaultCountry(
                                  props.values.country_id,
                                )}
                              />
                            </Box>
                          ),
                        },
                        {
                          currentLabel:
                            'PROOF OF RESIDENCY (MUST BE IN ENGLISH)',
                          currentValue: ' ',
                          vertical: true,
                          child: (
                            <FormFileDropzone
                              containerWidth="50%"
                              name="proofOfIdentity"
                            />
                          ),
                        },
                      ]}
                    />
                  </>
                )}
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box p={3}>
                {!!userAddressess[0] && (
                  <>
                    <CheckboxField
                      checkboxLabel="Change Address"
                      checked={checkedAddress}
                      onCheckedChange={(event) =>
                        setCheckedAddress(event.target.checked)
                      }
                      checkboxList={[
                        {
                          currentLabel: 'CURRENT FLOOR / UNIT NO',
                          currentValue: userAddressess[0].floor_unit_no,
                          child: (
                            <FormTextField
                              name="floor_unit_no"
                              labelText="NEW FLOOR / UNIT NO"
                              placeholder="#90-9"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT ADDRESS LINE 1',
                          currentValue: userAddressess[0].address_line_1,
                          child: (
                            <FormTextField
                              name="address_line_1"
                              labelText="NEW ADDRESS LINE 1"
                              placeholder="Orchard Road"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT ADDRESS LINE 2',
                          currentValue: userAddressess[0].address_line_2,
                          child: (
                            <FormTextField
                              name="address_line_2"
                              labelText="NEW ADDRESS LINE 2"
                              placeholder="Ngee Ann City Tower A"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT ADDRESS LINE 3',
                          currentValue: userAddressess[0].address_line_3,
                          child: (
                            <FormTextField
                              name="address_line_3"
                              labelText="NEW ADDRESS LINE 3"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT POSTCODE',
                          currentValue: userAddressess[0].postal_code,
                          child: (
                            <FormTextField
                              name="postal_code"
                              labelText="NEW POSTCODE"
                              placeholder="542902"
                              containerWidth="50%"
                              shrink
                              variant="outlined"
                            />
                          ),
                        },
                        {
                          currentLabel: 'CURRENT COUNTRY',
                          currentValue: userAddressess[0].country_name,
                          child: (
                            <Box
                              display="flex"
                              alignItems="flex-start"
                              flexDirection="column"
                              width={'50%'}
                            >
                              <FormDropdown
                                autoCompleteStyle={{ width: '100%' }}
                                name="country_id"
                                onChange={(event, newValue) => {
                                  props.setFieldValue(
                                    'country_id',
                                    newValue?.value,
                                  );
                                }}
                                optionList={countries}
                                labelText="NEW COUNTRY"
                                placeholder="AUSTRALIA"
                                fullWidth
                                shrink
                                variant="outlined"
                                defaultValue={getDefaultCountry(
                                  props.values.country_id,
                                )}
                              />
                            </Box>
                          ),
                        },
                        {
                          currentLabel:
                            'PROOF OF RESIDENCY (MUST BE IN ENGLISH)',
                          currentValue: ' ',
                          vertical: true,
                          child: (
                            <FormFileDropzone
                              containerWidth="50%"
                              name="proofOfIdentity"
                            />
                          ),
                        },
                      ]}
                    />
                  </>
                )}
              </Box>
            </TabPanel>

            <Box display="flex" justifyContent="flex-start" pl={3}>
              <MzButton onClick={() => {}} type="submit" title={'Continue'} />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          mobile_number: '',
        }}
        enableReinitialize
        onSubmit={step2formikOnSubmit}
        validationSchema={step2validationSchema}
        innerRef={stepformRef}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Divider />
            <Box p={3}>
              {!!userContacts[0] && (
                <>
                  <CheckboxField
                    checkboxLabel="Change Mobile Number"
                    checked={checkedMobile}
                    onCheckedChange={(event) =>
                      setCheckedMobile(event.target.checked)
                    }
                    checkboxList={[
                      {
                        currentLabel: 'CURRENT MOBILE NO',
                        currentValue:
                          getContactMobile1()?.country_phone_code_id +
                          getContactMobile1()?.contact_value,
                        child: (
                          <FormTextField
                            name="mobile_number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PhoneAndroidOutlinedIcon />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <ButtonBase
                                  onClick={() => setOtpVerification(true)}
                                >
                                  <Typography variant="h6" color="primary">
                                    Change
                                  </Typography>
                                </ButtonBase>
                              ),
                            }}
                            labelText="NEW MOBILE NO"
                            placeholder="+65 5578 9056"
                            containerWidth="50%"
                            shrink
                            variant="outlined"
                          />
                        ),
                      },
                    ]}
                  />
                  <CheckboxField
                    checkboxLabel="Change Email Address"
                    checked={checkedEmail}
                    onCheckedChange={(event) =>
                      setCheckedEmail(event.target.checked)
                    }
                    checkboxList={[
                      {
                        currentLabel: 'CURRENT EMAIL ADDRESS',
                        currentValue: getContactEmail()?.contact_value,
                        child: (
                          <FormTextField
                            name="email"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <MailOutlineIcon />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <ButtonBase
                                  onClick={() => setOtpVerification(true)}
                                >
                                  <Typography variant="h6" color="primary">
                                    Change
                                  </Typography>
                                </ButtonBase>
                              ),
                            }}
                            labelText="NEW EMAIL ADDRESS"
                            placeholder="john.smith@email.com"
                            containerWidth="50%"
                            shrink
                            variant="outlined"
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </Box>

            <Box display="flex" justifyContent="flex-start" pl={3}>
              <MzButton onClick={() => {}} type="submit" title={'Continue'} />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepThree = (props) => {
    return (
      <Formik
        initialValues={{
          nationality_country_id: '',
          identification_type_id: '',
          identification_value: '',
        }}
        enableReinitialize
        onSubmit={step3formikOnSubmit}
        validationSchema={step3validationSchema}
        innerRef={stepformRef}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Divider />
            <Box p={3}>
              {!!user && (
                <>
                  <CheckboxField
                    checkboxLabel="Change Nationality"
                    checked={checkedNationality}
                    onCheckedChange={(event) =>
                      setCheckedNationality(event.target.checked)
                    }
                    checkboxList={[
                      {
                        currentLabel: 'CURRENT NATIONALITY',
                        currentValue: user.nationality || '-',
                        child: (
                          <Box
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="column"
                            width={'50%'}
                          >
                            <FormDropdown
                              autoCompleteStyle={{ width: '100%' }}
                              name="nationality_country_id"
                              onChange={(event, newValue) => {
                                props.setFieldValue(
                                  'nationality_country_id',
                                  newValue?.value,
                                );
                              }}
                              optionList={countries}
                              labelText="NEW NATIONALITY"
                              placeholder="AUSTRALIA"
                              shrink
                              variant="outlined"
                            />
                          </Box>
                        ),
                      },
                      {
                        currentLabel: 'CURRENT ID TYPE',
                        currentValue: 'NRIC (CITIZEN)',
                        child: (
                          <Box
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="column"
                            width={'50%'}
                          >
                            <FormDropdown
                              autoCompleteStyle={{ width: '100%' }}
                              name="identification_type_id"
                              onChange={(event, newValue) => {
                                props.setFieldValue(
                                  'identification_type_id',
                                  newValue?.value,
                                );
                              }}
                              optionList={idTypes}
                              labelText="NEW ID TYPE"
                              placeholder="NRIC"
                              shrink
                              variant="outlined"
                            />
                          </Box>
                        ),
                      },
                      {
                        currentLabel: 'CURRENT ID',
                        currentValue: StringMasking(
                          `${user?.nric || user?.passport || ''}`,
                        ),
                        child: (
                          <FormTextField
                            name="identification_value"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <MailOutlineIcon />
                                </InputAdornment>
                              ),
                            }}
                            labelText="NEW ID"
                            placeholder="*****4310"
                            containerWidth="50%"
                            shrink
                            variant="outlined"
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </Box>

            <Box display="flex" justifyContent="flex-start" pl={3}>
              <MzButton onClick={() => {}} type="submit" title={'Continue'} />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepFour = () => {
    return (
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          deedPollFiles: [],
        }}
        enableReinitialize
        onSubmit={formikOnSubmit}
        validationSchema={step4validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Divider />
            <Box p={3}>
              <CheckboxField
                checkboxLabel="Change Name"
                checked={checkedName}
                onCheckedChange={(event) =>
                  setCheckedName(event.target.checked)
                }
                checkboxList={[
                  {
                    child: (
                      <>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          justifyContent="space-between"
                          flexDirection="column"
                        >
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="row"
                            style={{
                              gap: '50px',
                            }}
                            pb={3}
                          >
                            <Box
                              display="flex"
                              flex={1}
                              alignItems="flex-start"
                              flexDirection="column"
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                CURRENT FULL NAME
                              </Typography>
                              <Typography variant="subtitle1">
                                {`${user?.first_name} ${user?.last_name}`}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="row"
                            style={{
                              gap: '50px',
                            }}
                          >
                            <Box
                              width="100%"
                              display="flex"
                              flex={1}
                              alignItems="flex-start"
                              flexDirection="column"
                            >
                              <FormTextField
                                name="first_name"
                                labelText="FIRST NAME"
                                labelProps={{
                                  variant: 'caption',
                                  color: 'textSecondary',
                                }}
                                placeholder="WAY SIONG"
                                fullWidth
                                shrink
                                variant="outlined"
                              />
                            </Box>
                            <Box
                              flex={1}
                              display="flex"
                              alignItems="flex-start"
                              flexDirection="column"
                            >
                              <FormTextField
                                labelText="LAST NAME"
                                name="last_name"
                                labelProps={{
                                  variant: 'caption',
                                  color: 'textSecondary',
                                }}
                                placeholder="GOH"
                                fullWidth
                                shrink
                                variant="outlined"
                              />
                            </Box>
                          </Box>

                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="row"
                            style={{
                              gap: '50px',
                            }}
                          >
                            <Box
                              width="100%"
                              display="flex"
                              flex={1}
                              alignItems="flex-start"
                              flexDirection="column"
                            >
                              <FormDatePicker
                                labelProps={{
                                  variant: 'caption',
                                  color: 'textSecondary',
                                }}
                                containerWidth="100%"
                                name="name_change_date"
                                labelText="DATE OF NAME CHANGE"
                                format="dd/MM/yyyy"
                                variant="outlined"
                                inputVariant="outlined"
                              />
                            </Box>
                            <Box
                              flex={1}
                              display="flex"
                              alignItems="flex-start"
                              flexDirection="column"
                            >
                              <FormDatePicker
                                labelProps={{
                                  variant: 'caption',
                                  color: 'textSecondary',
                                }}
                                containerWidth="100%"
                                name="deed_poll_date"
                                labelText="DATE OF DEED POLL"
                                format="dd/MM/yyyy"
                                variant="outlined"
                                inputVariant="outlined"
                              />
                            </Box>
                          </Box>

                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="row"
                          >
                            <FormFileDropzone
                              labelProps={{
                                variant: 'caption',
                                color: 'textSecondary',
                              }}
                              containerWidth="100%"
                              name="deedPollFiles"
                              labelText="DEED POLL OR EVIDENTIAL DOCUMENT"
                            />
                          </Box>
                        </Box>
                      </>
                    ),
                  },
                ]}
              />
            </Box>

            <Box display="flex" justifyContent="flex-start" pl={3}>
              <MzButton
                loading={submitting}
                onClick={() => {}}
                type="submit"
                title={'Submit'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const step1formikOnSubmit = async (values, actions) => {
    setStep((prevState) => {
      return prevState + 1;
    });
  };

  const step2formikOnSubmit = async (values, actions) => {
    setStep((prevState) => {
      return prevState + 1;
    });
  };

  const step3formikOnSubmit = async (values, actions) => {
    setStep((prevState) => {
      return prevState + 1;
    });
  };

  const formikOnSubmit = async (values, actions) => {
    if (step === 3) {
      setSubmitting(true);
      values.state_id = 1;
      values.company_application_status_id = 11; // in cart status
      values.user_id = params.id;
      values.created_by = loggedInUser.user_id;
      values.company_id = company.company_id;
      values.individual_type_id = 1;
      try {
        if (values.proofOfIdentity?.length > 0) {
          const proofOfIdentityID = await S3UploadAndInsertDocuments(
            values.proofOfIdentity[0],
            76,
            6,
            'officer_application_id',
          );
          values.identity_attachment_id = proofOfIdentityID.document_id;
        }

        if (values.deedPollFiles?.length > 0) {
          const deedPollFilesID = await S3UploadAndInsertDocuments(
            values.deedPollFiles[0],
            76,
            6,
            'officer_application_id',
          );
          values.deed_poll_attachment_id = deedPollFilesID.document_id;
        }
      } catch (err) {
        setSubmitting(false);
        showErrorSnackbar('Error uploading documents');
        return;
      }

      let res = await CreateUpdateParticularsApplication(values);
      if (res.status === 'success') {
        res = await addCart({
          price: product.price,
          product_id: product.id,
          quantity: 1,
          user_id: loggedInUser.user_id,
          product_detail_id:
            res.body.applications.individual_particular_application_id,
          company_id: company.company_id,
        });
        if (res.status === 'success') {
          actions.resetForm();
          setConfirmationDialog({
            isVisible: true,
            title: 'Item added to cart',
            type: 'success',
            cancelButtonLabel: 'Back to Request',
            confirmButtonLabel: 'View your cart',
            confirmButtonAction: () => {
              history.push(ROUTES.PAYMENTCART);
              setConfirmationDialog({
                isVisible: false,
              });
            },
            cancelButtonAction: () => {
              history.push(ROUTES.CORPSEC_REQ);
              setConfirmationDialog({
                isVisible: false,
              });
            },
          });
        }
      }
      setSubmitting(false);
    }
  };

  return (
    <>
      <OtpAlert
        isVisible={otpVerification}
        onBackdropPress={() => setOtpVerification(false)}
        onClick={() => setOtpVerification(false)}
        onPressResend={() => {}}
        onPressContactUs={() => {}}
      />
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={2}
        setStep={() => {
          history.push(ROUTES.CORPSEC_REQ_DIRECTOR_LIST);
        }}
        header={
          <>
            <Typography variant="h2">Change Director Particulars</Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Personal Particulars',
                  route: ROUTES.CORPSEC_REQ_DIRECTOR_LIST,
                },
                {
                  path: 'Change Director Particulars',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        <Stepper
          steps={tempSteps}
          providedStep={step}
          setProvidedStep={async (newStep) => {
            if (stepformRef.current) {
              await stepformRef.current.validateForm();

              if (!stepformRef.current.isValid) {
                stepformRef.current.handleSubmit();
                return;
              }
              setStep(newStep);
            } else {
              setStep(newStep);
            }
          }}
        />
        {step === 0 && stepOne()}
        {step === 1 && stepTwo()}
        {step === 2 && stepThree()}
        {step === 3 && stepFour()}
        <Box p={2} />
      </GetStartedLayout>
    </>
  );
};

export default ChangeDirectorParticulars;
