import { useContext, useState, createContext } from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';

const cartContext = createContext({
  carts: [],
  cartError: '',
  cartLoading: false,
  cartSubmitting: false,
  AddCart: () => Promise.resolve({}),
  ListCart: () => Promise.resolve({}),
  DeleteCartItem: () => Promise.resolve({}),
  UpdateCartItems: () => Promise.resolve({}),
});

export function useProvideCart() {
  const [carts, setCarts] = useState([]);
  const [cartError, setCartError] = useState();
  const [cartLoading, setCartLoading] = useState(false);
  const [cartSubmitting, setCartSubmitting] = useState(false);
  const [order, setOrder] = useState({});

  const ListCart = async (userID) => {
    // https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/cart/user/1
    // "user_id":1
    const res = await APIRequest({
      setError: setCartError,
      setLoading: setCartLoading,
      path: `${process.env.REACT_APP_ORDER_URL}/cart/user/${userID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      // concat last item from res
      setCarts(res.body.cart?.items || []);
    }
    return res;
  };

  const AddCart = async (cart = {}) => {
    // "product_id": 1,
    // "quantity": 1,
    // "user_id":1
    const res = await APIRequest({
      setError: setCartError,
      setLoading: setCartSubmitting,
      path: `${process.env.REACT_APP_ORDER_URL}/cart/additem`,
      method: 'post',
      body: cart,
    });
    if (res.status === 'success') {
      // concat last item from res
      res.body.cart = res.body.items[res.body.items.length - 1];
      setCarts(carts.concat(res.body.cart));
    }
    return res;
  };

  const DeleteCartItem = async (cartID) => {
    const res = await APIRequest({
      setError: setCartError,
      setLoading: setCartSubmitting,
      path: `${process.env.REACT_APP_ORDER_URL}/cart/removeitem/${cartID}`,
      method: 'delete',
    });
    if (res.status === 'success') {
      const cartIndex = carts.findIndex((c) => c.id === cartID);
      if (cartIndex !== -1) {
        setCarts(carts.splice(cartIndex, 1));
      }
    }
    return res;
  };

  const UpdateCartItems = async (cartItems = []) => {
    // "id": 37, item id from ^
    // "quantity": 5,
    // "product_id": 1,
    // "user_id":1

    const promises = cartItems.map((item) => {
      return APIRequest({
        setError: () => Promise.resolve({}),
        setLoading: () => Promise.resolve({}),
        path: `${process.env.REACT_APP_ORDER_URL}/cart/updateitem`,
        method: 'post',
        body: item,
      });
    });

    const result = await Promise.allSettled(promises);

    const isReject = result.find((res) => res.status === 'rejected');
    if (isReject) {
      setCartError(isReject.reason);
      setCartSubmitting(false);
      return {
        message: isReject.reason,
        status: 'rejected',
      };
    }
    return {
      body: item,
      status: 'success',
    };
  };

  return {
    carts,
    cartError,
    cartLoading,
    cartSubmitting,
    order,
    AddCart,
    ListCart,
    DeleteCartItem,
    UpdateCartItems,
  };
}

export const useCartContext = () => useContext(cartContext);

export function ProvideCart({ children }) {
  const cart = useProvideCart();
  return <cartContext.Provider value={cart}>{children}</cartContext.Provider>;
}
