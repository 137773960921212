import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Box,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Tabs,
  Tab,
  InputAdornment,
  ButtonBase,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form, FieldArray } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { useProvideShareholder } from 'src/hooks/useShareholders';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import Stepper from 'src/components/Stepper';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import DetailLabel from 'src/components/DetailLabel';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import { Icon } from '@iconify/react';
import companySvc from 'src/services/company';
import { ConfigContext } from 'src/contexts';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import useSnackbar from 'src/hooks/useSnackbar';
import { StringMasking } from 'src/utils/string';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TodayIcon from '@material-ui/icons/Today';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTheme } from '@material-ui/core/styles';
import DottedLabel from 'src/components/DottedLabel';
import RowTable from 'src/components/RowTable';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import CloseIcon from '@material-ui/icons/Close';

const tempSteps = [
  {
    id: 2,
    label: 'Transferor',
  },
  {
    id: 3,
    label: 'Select Shareholder',
  },
  {
    id: 4,
    label: 'Transferee',
  },
];

const currencyList = [
  { label: 'EUR' },
  { label: 'GBP' },
  { label: 'JPY' },
  { label: 'SGD' },
  { label: 'USD' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: 'black',
    display: 'flex',
    alignItems: 'left',
    textAlign: 'left',
    backgroundColor: '#ECECEC',
  },
  fontWeight: {
    fontWeight: 600,
  },
}));

const TransferShares = ({ toggleShowMenu }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { smBelow } = useBreakpoints();
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [shareToTransfer, setShareToTransfer] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [countryCode, setCountryCode] = useState('SG');
  const [transferShareholders, setTransferShareholders] = useState([]);
  const [shareholderOptions, setShareholderOptions] = useState([]);
  const [selectedSH, setSelectedSH] = useState([]);

  const {
    addCart,
    user,
    cartSubmitting,
    setConfirmationDialog,
    company,
  } = useContext(ConfigContext);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { ReadProduct, product, productError } = useProvideProduct();
  const {
    GetShareholderByUserID,
    shareholderError,
    shareholder,
    shareholders,
    newshareholder,
    ListShareholders,
    ApplyNewShareholder,
  } = useProvideShareholder();
  const {
    CreateShareTransferApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();

  useEffect(() => {
    // get latest product
    ReadProduct(21);
    GetShareholderByUserID(user.user_id);
  }, []);

  useEffect(() => {
    if (company.company_id) {
      ListShareholders(company.company_id);
    }
  }, [company.company_id]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (shareholderError) {
      showErrorSnackbar(`Error loading shareholder info. ${shareholderError}`);
    }
  }, [shareholderError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const getShareholderByCompany = (shareholders = []) => {
    return shareholders.find((sh) => sh.company_id === company.company_id);
  };

  useEffect(() => {
    if (shareholders.length > 0) {
      setShareholderOptions(populateShareholderOptions(shareholders));
    }
  }, [shareholders]);

  const step2validationSchema = Yup.object({
    number_of_share_transfer: Yup.number()
      .max(
        getShareholderByCompany(shareholder)?.user_allotment || 0,
        'Cannot be more than your share',
      )
      .min(0, 'Cannot be less than 0')
      .required('Shares to Transfer is required'),
  });

  const step3validationSchema = Yup.object().shape({
    new_shareholders: Yup.array().of(
      Yup.object().shape({
        number_share_transfer: Yup.string()
          .matches(/^[0-9]+$/, 'Must be only digits')
          .required('Share to Transfer is required'),
      }),
    ),
  });

  const step4validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    identityFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 0,
      then: Yup.array().min(1),
    }),
    residencyFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
    passportFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
  });

  const getStarted = {
    one: {
      content: [
        <Typography
          key={''}
          align="justify"
          variant="body2"
          color="textPrimary"
          display="inline"
        >
          {
            'Transfer of Ordinary Shares from existing shareholder to other existing or new'
          }
          <Typography
            align="justify"
            variant="body2"
            color="textPrimary"
            display="inline"
            className={classes.fontWeight}
          >
            {' Individual '}
          </Typography>
          {'shareholders'}
        </Typography>,
        'For transfer of other share type and currency, please submit under Other Request',
      ],
    },
    two: {
      content: [
        'Shareholder particulars & copy of NRIC/ Passport (For New Shareholder)',
        'Transfer Details (Number of Ordinary Shares & Considerations for each shareholder)',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['Basic Plan: SGD100', 'Unlimited Plan: SGD100'],
    },
    five: {
      content: [
        'Time to fill form: < 5 minutes.',
        'Average processing time: 10-15 days',
      ],
    },
  };

  const calcShareBalance = () => {
    return (
      shareToTransfer -
      transferShareholders.reduce((acc, shareholder) => {
        return acc + Number(shareholder.number_share_transfer);
      }, 0)
    );
  };

  const populateShareholderOptions = (shareholders = []) => {
    return shareholders.map((sh) => ({
      label: sh.Name,
      value: sh.shareholder_id,
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const formikOnSubmitStep2 = async (values, actions) => {
    setShareToTransfer(values.number_of_share_transfer);
    setStep(3);
  };

  const formikOnSubmit = async (values, actions) => {
    if (calcShareBalance() < 0) {
      return showErrorSnackbar(`Share exceed available amount to transfer.`);
    }
    setLoading(true);
    values.company_id = company.company_id;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;
    values.transfer_date = new Date();
    values.total_share_transfer = shareToTransfer;

    let res = await CreateShareTransferApplication(values);
    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.share_transfer_application_id,
        company_id: company.company_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
    setLoading(false);
  };

  const formikOnSubmitNewShareholder = async (values, actions) => {
    setLoading(true);
    if (values.identityFiles.length > 0) {
      const identityFilesID = await S3UploadAndInsertDocuments(
        values.identityFiles[0],
        76,
        6,
        'officer_application_id',
      );
      values.proof_identity_attachment_id = identityFilesID.document_id;
    }

    if (values.residencyFiles.length > 0) {
      const residencyFilesID = await S3UploadAndInsertDocuments(
        values.residencyFiles[0],
        112,
        6,
        'officer_application_id',
      );
      values.proof_residency_attachment_id = residencyFilesID.document_id;
    }

    if (values.passportFiles.length > 0) {
      const passportFilesID = await S3UploadAndInsertDocuments(
        values.passportFiles[0],
        77,
        6,
        'officer_application_id',
      );
      values.passport_attachment_id = passportFilesID.document_id;
    }
    transferShareholders.push({
      company_id: company.company_id,
      company_application_status_id: 11,
      is_foreigner: tabValue == 0 ? 0 : 1,
      shareholder_name: values.name,
      identity_id: values.nric_passport_no,
      mobile_number: values.mobile_number,
      email: values.email,
      proof_identity_attachment_id: values.proof_identity_attachment_id,
      passport_attachment_id: values.passport_attachment_id,
      proof_residency_attachment_id: values.proof_residency_attachment_id,
      created_by: user.user_id,
      number_share_transfer: '',
      is_new_shareholder: 1,
      transfer_from_id: getShareholderByCompany(shareholder)?.shareholder_id,
      transfer_to_id: 0,
    });
    setTransferShareholders(transferShareholders);
    setLoading(false);
    actions.resetForm();
    setStep(4);
    // let res = await ApplyNewShareholder({
    //   company_id: company.company_id,
    //   company_application_status_id: 11,
    //   is_foreigner: tabValue == 0 ? 0 : 1,
    //   shareholder_name: values.name,
    //   identity_id: values.nric_passport_no,
    //   mobile_number: values.mobile_number,
    //   email: values.email,
    //   proof_identity_attachment_id: values.proof_identity_attachment_id,
    //   passport_attachment_id: values.passport_attachment_id,
    //   proof_residency_attachment_id: values.proof_residency_attachment_id,
    //   created_by: user.user_id,
    // });
    // setLoading(false);
    // if (res.status === 'success') {
    //   actions.resetForm();
    //   setStep(3);
    // }
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          number_of_share_transfer: shareToTransfer,
        }}
        onSubmit={formikOnSubmitStep2}
        validationSchema={step2validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Paper
              style={{
                width: '100%',
                borderRadius: 5,
                padding: '20px',
              }}
            >
              <Box display="flex" alignItems="center" pb={5}>
                <Icon
                  icon="carbon:user-avatar-filled"
                  color="black"
                  width="20"
                  height="20"
                />
                <Box p={1} />
                <Typography variant="h6" align="left">
                  {user.first_name} {user.last_name}
                </Typography>
              </Box>
              <Box display="flex" flexDirection={smBelow ? 'column' : 'row'}>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={props.fullWidth ? '100%' : '50%'}
                >
                  <EnhancedTextField
                    disabled
                    labelText="Existing No. of Shares"
                    value={getShareholderByCompany(shareholder)?.user_allotment}
                    color="primary"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                <FormTextField
                  name="number_of_share_transfer"
                  labelText="Shares to Transfer"
                  variant="outlined"
                  type="number"
                  placeholder="0"
                  width={smBelow ? '100%' : '70%'}
                />
                {!smBelow && <Box width="30%" />}
              </Box>
            </Paper>
            <Box display="flex" pt={3} justifyContent="flex-end">
              <MzButton
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {}}
                title={'Proceed'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepThree = () => {
    return (
      <>
        <DottedLabel
          label="Transfer From:"
          value={user.first_name + ' ' + user.last_name}
        />
        <Grid container>
          <RowTable
            label="Existing No. of Shares"
            latest={getShareholderByCompany(shareholder)?.user_allotment}
          />
          <RowTable label="Shares to Transfer" latest={calcShareBalance()} />
          <RowTable
            label="Balance"
            latest={
              getShareholderByCompany(shareholder)?.user_allotment -
              shareToTransfer
            }
          />
        </Grid>
        <Box py={3} />
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          style={{ gap: 20 }}
        >
          <Paper
            variant={'outlined'}
            style={{
              borderRadius: smBelow ? '0px' : '4px',
              flex: '1 1 0%',
            }}
          >
            <ListItem
              style={{
                backgroundColor: '#F5F5F5',
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  },
                  variant: 'body2',
                }}
                primary={'Select Existing Shareholders'}
              />
            </ListItem>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight={'50px'}
              px={7}
              py={7}
              flex={1}
            >
              <GroupOutlinedIcon
                style={{
                  width: '60px',
                  height: '60px',
                  fill: 'black',
                  fillOpacity: '0.34',
                }}
              />
              <Autocomplete
                multiple
                onClose={() => {
                  if (transferShareholders.length > 0) {
                    setStep(4);
                  }
                }}
                options={shareholderOptions}
                disableCloseOnSelect
                value={selectedSH}
                getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => {
                  return (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                      />
                      {option.label}
                    </React.Fragment>
                  );
                }}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select" />
                )}
                onChange={(event, values = [], reason) => {
                  event.preventDefault();
                  let selectedShareholders = [];
                  setSelectedSH(values);
                  if (reason === 'select-option') {
                    values.forEach((selectedShareholder) => {
                      const selectedSHData = shareholders.find(
                        (sh) => sh.shareholder_id === selectedShareholder.value,
                      );
                      const inTransferList = transferShareholders.find(
                        (tsh) => tsh.id === selectedSHData.shareholder_id,
                      );
                      if (inTransferList) return;

                      return selectedShareholders.push({
                        id: selectedSHData.shareholder_id,
                        company_id: company.company_id,
                        company_application_status_id: 11,
                        shareholder_name: selectedSHData.Name,
                        created_by: user.user_id,
                        number_share_transfer: '',
                        is_new_shareholder: 0,
                        transfer_from_id: getShareholderByCompany(shareholder)
                          ?.shareholder_id,
                        transfer_to_id: selectedSHData.shareholder_id,
                      });
                    });
                    selectedShareholders = [
                      ...selectedShareholders,
                      ...transferShareholders,
                    ];
                  }
                  if (reason === 'remove-option' || reason === 'clear') {
                    // remove from selection
                    selectedShareholders = transferShareholders.filter(
                      (tsh) => {
                        if (tsh.is_new_shareholder === 1) return true;
                        return values.find((sh) => sh.value === tsh.id);
                      },
                    );
                  }

                  setTransferShareholders([...selectedShareholders]);
                }}
              />
            </Box>
          </Paper>

          <Paper
            variant={'outlined'}
            style={{
              borderRadius: smBelow ? '0px' : '4px',
              flex: '1 1 0%',
            }}
          >
            <ListItem
              style={{
                backgroundColor: '#F5F5F5',
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  },
                  variant: 'body2',
                }}
                primary={'Add New Shareholders'}
              />
            </ListItem>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight={'50px'}
              flex={1}
              px={7}
              py={7}
            >
              <GroupAddOutlinedIcon
                style={{
                  width: '60px',
                  height: '60px',
                  fill: 'black',
                  fillOpacity: '0.34',
                }}
              />
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                size="large"
                onClick={() => {
                  setStep(5);
                }}
              >
                Add New
              </Button>
            </Box>
          </Paper>
        </Box>
      </>
    );
  };

  const stepFour = () => {
    return (
      <>
        <DottedLabel
          label="Transfer From:"
          value={user.first_name + ' ' + user.last_name}
        />
        <Grid container>
          <RowTable
            label="Existing No. of Shares"
            latest={getShareholderByCompany(shareholder)?.user_allotment}
          />
          <RowTable label="Shares to Transfer" latest={calcShareBalance()} />
          <RowTable
            label="Balance"
            latest={
              getShareholderByCompany(shareholder)?.user_allotment -
              shareToTransfer
            }
          />
        </Grid>
        <Box py={3} />

        <Formik
          initialValues={{
            new_shareholders: [],
          }}
          onSubmit={formikOnSubmit}
          validationSchema={step3validationSchema}
          enableReinitialize
        >
          {(props) => (
            <Form style={{ width: '100%' }}>
              <Box
                p={2}
                display="flex"
                flexDirection={smBelow ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={smBelow ? 'flex-start' : 'center'}
                width="100%"
              >
                <DottedLabel value="Allotment Details" />
                <ButtonBase
                  style={{ justifyContent: 'flex-start', color: '#A21D23' }}
                  onClick={() => {
                    setStep(5);
                  }}
                >
                  <Typography variant="h6">Add New Shareholder</Typography>
                  <ChevronRightIcon />
                </ButtonBase>
              </Box>
              <Paper variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Shareholder</TableCell>
                      <TableCell>Shares</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="new_shareholders">
                      {() => (
                        <>
                          {transferShareholders.map((shareholder, index) => (
                            <TableRow key={shareholder.id}>
                              <TableCell>
                                <IconButton
                                  onClick={() => {
                                    transferShareholders.splice(index, 1);
                                    setTransferShareholders([
                                      ...transferShareholders,
                                    ]);
                                    if (shareholder.is_new_shareholder === 0) {
                                      // remove from selected option
                                      const indexToRemove = selectedSH.findIndex(
                                        (ssh) => {
                                          return ssh.value === shareholder.id;
                                        },
                                      );
                                      if (indexToRemove !== -1) {
                                        selectedSH.splice(indexToRemove, 1);
                                        setSelectedSH(selectedSH);
                                      }
                                    }
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>
                                {shareholder.shareholder_name}
                              </TableCell>
                              <TableCell>
                                <FormTextField
                                  name={`new_shareholders.${index}.number_share_transfer`}
                                  variant="outlined"
                                  value={shareholder.number_share_transfer}
                                  onChange={(event) => {
                                    props.setFieldValue(
                                      `new_shareholders.${index}.number_share_transfer`,
                                      event.target.value,
                                    );
                                    transferShareholders[
                                      index
                                    ].number_share_transfer =
                                      event.target.value;
                                    setTransferShareholders([
                                      ...transferShareholders,
                                    ]);
                                  }}
                                  // width={smBelow ? '100%' : '33%'}
                                  fullWidth
                                  paddingBottom={false}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </TableBody>
                </Table>
              </Paper>
              <Box p={2} />
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ gap: '10px' }}
              >
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: '#9E9E9E',
                    color: 'white',
                    textTransform: 'initial',
                  }}
                  onClick={() => {
                    setStep(3);
                  }}
                >
                  Back to Select Shareholders
                </Button>
                <MzButton
                  disabled={calcShareBalance() !== 0}
                  type="submit"
                  onClick={async () => {
                    props.setFieldValue(
                      'new_shareholders',
                      [...transferShareholders],
                      true,
                    );
                    props.setFieldTouched('new_shareholders', true, true);
                  }}
                  title={'Submit'}
                  loading={loading}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  const addNewShareholder = () => {
    return (
      <>
        <Typography variant="h6" align="left" className={classes.fontWeight}>
          Fill in information for New Shareholder
        </Typography>
        <Box p={1} />
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          style={{
            borderBottom: '0.01rem solid #E4E4E4',
            paddingLeft: theme.spacing(smBelow ? 3 : 12),
          }}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="Local"
            style={{
              textTransform: 'initial',
              fontSize: 16,
            }}
          />
          <Tab
            label="Foreigner"
            style={{
              textTransform: 'initial',
              fontSize: 16,
            }}
          />
        </Tabs>
        <Box p={1} />
        <TabPanel value={tabValue} index={0}>
          {renderForm()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderForm()}
        </TabPanel>
      </>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_role_id: 5,
          name: '',
          nric_passport_no: '',
          email: '',
          mobile_number: '',
          identityFiles: [],
          residencyFiles: [],
          passportFiles: [],
        }}
        onSubmit={formikOnSubmitNewShareholder}
        validationSchema={step4validationSchema}
      >
        {(props) => (
          <Box p={3} pl={smBelow ? 3 : 12}>
            <Form>
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="name"
                labelText={
                  tabValue === 0
                    ? 'NAME (as in NRIC/FIN) *'
                    : 'NAME (as in PASSPORT) *'
                }
                placeholder="Name"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="nric_passport_no"
                labelText="ID *"
                placeholder="ID"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormDropdownTextField
                name="mobile_number"
                labelText="Mobile Number *"
                placeholder="91234567"
                containerWidth={smBelow ? '100%' : '50%'}
                fullWidth
                shrink
                variant="outlined"
                showFlag={false}
                onValueChange={(event, newValue) => {
                  setCountryCode(newValue.code);
                }}
                countryCode={countryCode}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="email"
                labelText="Email Address *"
                placeholder="Email Address"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {tabValue === 0 && (
                <FormFileDropzone
                  containerWidth={smBelow ? '100%' : '50%'}
                  name="identityFiles"
                  labelText="Proof of Identity *"
                />
              )}

              {tabValue === 1 && (
                <Box
                  display="flex"
                  flexDirection={smBelow ? 'column' : 'row'}
                  style={{
                    gap: '20px',
                  }}
                >
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="passportFiles"
                    labelText="Copy of Passport *"
                  />
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="residencyFiles"
                    labelText="Proof of Residency (Must be in English) *"
                  />
                </Box>
              )}

              <Box display="flex" pt={3} justifyContent="flex-start">
                <MzButton
                  // disabled={!(props.isValid && props.dirty)}
                  fullWidth={smBelow ? true : false}
                  type="submit"
                  title={'SAVE'}
                  onClick={() => {}}
                  loading={loading}
                />
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Transfer of Shares'}
              {(step === 2 || step === 3 || step === 4) && 'Transfer Details'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Share Information',
                  route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
                },
                {
                  path: 'Transfer of Shares',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step !== 1 && (
          <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
            {(step === 2 || step === 3 || step === 4) && (
              <Stepper
                steps={tempSteps}
                providedStep={step - 2}
                setProvidedStep={(step) => {
                  setStep(step + 2);
                }}
              />
            )}
            {step === 2 && stepTwo()}
            {step === 3 && stepThree()}
            {step === 4 && stepFour()}
            {step === 5 && addNewShareholder()}
          </Box>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default TransferShares;
