const ROUTES = {
  ONBOARDINGCOMPANY: '/onboarding-company',
  DASHBOARD: '/dashboard',
  HOMEPAGE: '/',
  OTPPAGE: '/otp',
  MESSAGEPAGE: '/msg',
  SIGNUPPAGE: '/signup',
  SIGNINPAGE: '/signin',
  LOGINFAILED: '/login-failed',
  PAYMENTCART: '/payment',
  CHANGE_PASSWORD: '/change-password',
  SETTINGS: '/settings',
  PAGENOTFOUND: '/page-not-found',
  SCHEDULEDMAINTENANCE: '/scheduled-maintenance',
  ORDERHISTORY: '/orderhistory',
  ORDERHISTORYDETAILS: (id) => `/orderhistory/${id}`,
  CORPSEC: '/corpsec',
  CORPSEC_DOC: '/corpsec/documents',
  CORPSEC_REQ: '/corpsec/requests',
  CORPSEC_PRO: '/corpsec/profile',
  CORPSEC_TASK: '/corpsec/tasklist',
  CORPSEC_BOARD: '/corpsec/boardroom',
  CORPSEC_CAP: '/corpsec/captable',
  CORPSEC_MANAGEUSER: '/corpsec/manage-user',
  CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS:
    '/corpsec/requests/change-company-particulars',
  CORPSEC_REQ_CHANGE_COMPANY_ADDRESS:
    '/corpsec/requests/change-company-particulars/company-address',
  CORPSEC_REQ_CHANGE_COMPANY_FINANCIAL_YEAR_END:
    '/corpsec/requests/change-company-particulars/financial-year-end',
  CORPSEC_REQ_CHANGE_COMPANY_NAME:
    '/corpsec/requests/change-company-particulars/company-name',
  CORPSEC_REQ_CHANGE_SHARE_INFORMATION:
    '/corpsec/requests/change-share-information',
  CORPSEC_REQ_ALLOTMENT_SHARES:
    '/corpsec/requests/change-share-information/allotment-of-shares',
  CORPSEC_REQ_TRANSFER_SHARES:
    '/corpsec/requests/change-share-information/transfer-of-shares',
  CORPSEC_REQ_ALLOTMENT_SHARES_NEW:
    '/corpsec/requests/change-share-information/allotment-of-shares-new',
  CORPSEC_REQ_TRANSFER_SHARES_NEW:
    '/corpsec/requests/change-share-information/transfer-of-shares-new',
  CORPSEC_REQ_CHANGE_BUSINESS_ACTIVITIES:
    '/corpsec/requests/change-company-particulars/change-business-activities',
  CORPSEC_REQ_FILLING_OTHER_RESOLUTIONS:
    '/corpsec/requests/filling-other-resolutions',
  CORPSEC_REQUESTS_DECLARE_INTERIM_DIVIDEND:
    '/corpsec/requests/filling-other-resolutions/declare-interim-dividend',
  CORPSEC_REQUESTS_OPEN_BANK_ACCOUNT:
    '/corpsec/requests/filling-other-resolutions/open-bank-account',
  CORPSEC_REQUESTS_CLOSE_BANK_ACCOUNT:
    '/corpsec/requests/filling-other-resolutions/close-bank-account',
  CORPSEC_REQUESTS_OTHER_REQUEST:
    '/corpsec/requests/filling-other-resolutions/other-request',
  CORPSEC_REQ_CHANGE_DIRECTOR_PARTICULARS: (id) =>
    `/corpsec/requests/change-personal-particulars/directors/${id}`,
  CORPSEC_REQ_CHANGE_SHAREHOLDER_PARTICULARS: (id) =>
    `/corpsec/requests/change-personal-particulars/shareholders/${id}`,
  CORPSEC_REQ_CHANGE_OFFICER: '/corpsec/requests/change-officer',
  CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS:
    '/corpsec/requests/change-personal-particulars',
  CORPSEC_REQ_DIRECTOR_LIST:
    '/corpsec/requests/change-personal-particulars/directors',
  CORPSEC_REQ_SHAREHOLDER_LIST:
    '/corpsec/requests/change-personal-particulars/shareholders',
  CORPSEC_REQ_APPT_ADMIN:
    '/corpsec/requests/change-officer/administrator-appointment',
  CORPSEC_REQ_APPT_DIRECTOR:
    '/corpsec/requests/change-officer/director-appointment',
  CORPSEC_REQ_RESGN_ADMIN:
    '/corpsec/requests/change-officer/administrator-resignation',
  CORPSEC_REQ_RESGN_ADMIN_INFO: (id) =>
    `/corpsec/requests/change-officer/administrator-resignation/${id}`,
  CORPSEC_REQ_RESGN_DIRECTOR:
    '/corpsec/requests/change-officer/director-resignation',
  CORPSEC_REQ_RESGN_DIRECTOR_INFO: (id) =>
    `/corpsec/requests/change-officer/director-resignation/${id}`,
  WEBSERVICE: '/webservice',
  WEBSERVICE_CATEGORY: (packageid) => `/webservice/${packageid}/category`,
  WEBSERVICE_THEME: (packageid, categoryid) =>
    `/webservice/${packageid}/category/${categoryid}/theme`,
  WEBSERVICE_THEME_INFO: (packageid, categoryid, themeid) =>
    `/webservice/${packageid}/category/${categoryid}/theme/${themeid}`,
  WEBSERVICE_PLAN: (packageid, categoryid, themeid) =>
    `/webservice/${packageid}/category/${categoryid}/theme/${themeid}/plan`,
  WEBDEV: '/webdev',
  CREATEWEBSITE: (webdevid) => `/createwebsite/${webdevid}`,
  CREATEWEBSITE_CONTENT: (webdevid) => `/createwebsite/${webdevid}/content`,
  CREATEWEBSITE_CORPIDENTITY: (webdevid) =>
    `/createwebsite/${webdevid}/content/corpidentity`,
  CREATEWEBSITE_IMAGES: (webdevid) =>
    `/createwebsite/${webdevid}/content/corpidentity/images`,
  CORPSEC_COMPANY_ACCOUNT_SETTINGS: '/corpsec/company-account-settings',
  ONBOARDING_COMPANY: '/onboarding-company',
  CONTACT_SUPPORT: '/contact-support',
};

export default ROUTES;
