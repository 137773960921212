import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  MenuItem,
  Typography,
  Container,
  Avatar,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RepeatIcon from '@material-ui/icons/Repeat';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import timelineSvc from 'src/services/timeline';
import InfoBox from 'src/components/InfoBox';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import StarIcon from '@material-ui/icons/Star';
import 'react-vertical-timeline-component/style.min.css';
import { Box } from '@material-ui/core';
import './styles.css';
import useStyles from './Dashboard.styles';
import DashLayout from 'src/components/Corpsec/Dashboard/Layout';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { ConfigContext } from 'src/contexts';
import { useProvideTimeline } from 'src/hooks/useTimeline';
import notificationIcon from 'src/assets/notifications/notification-icon.png';

const Dashboard = () => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const [loading, setLoading] = useState(true);
  const [year, setYear] = React.useState('2021');
  const [upcomingList, setUpcomingList] = useState([]);
  const [yearTimeline, setYearTimeline] = React.useState([]);
  const { company } = useContext(ConfigContext);
  const { ListTimeline, timelineList, tlLoading } = useProvideTimeline();

  useEffect(() => {
    if (Object.keys(timelineList).length !== 0) {
      let timelineByYear = timelineList.filter(
        (item) => new Date(item.timeline_date).getFullYear() == year,
      );
      setYearTimeline(timelineByYear);
    }
  }, [year, timelineList]);

  useEffect(() => {
    if (company && company.company_id) {
      (async () => {
        try {
          ListTimeline();
          const data = await timelineSvc.timeline(company?.company_id);
          const filteredData = data.filter(
            (item) => item.event_action == 'Pending Review',
          );
          let sortedData = filteredData.sort((a, b) =>
            a.timeline_id < b.timeline_id ? 1 : -1,
          );
          if (sortedData) setUpcomingList(sortedData);
          setLoading(false);
        } catch (err) {
          console.log('timeline', err);
          setLoading(false);
        }
      })();
    }
  }, [company]);

  const renderTimeline = (list) => {
    return list.map((item, index) => (
      <Timeline
        key={`${item.log_timeline_id}`}
        title={item.action}
        subtitle={item.notes}
        month={new Date(item.timeline_date).toLocaleString('default', {
          month: 'short',
        })}
        date={new Date(item.timeline_date).getDate()}
        status={item.action_type}
        // due={item.countdown_days}
      />
    ));
  };

  const renderEmptyTimeline = (title, subtitle) => {
    return (
      <>
        <Box display="flex" justifyContent="center" p={5}>
          <img
            src={notificationIcon}
            style={{ width: '100px', height: '100px' }}
          />
        </Box>
        <Typography variant="h4" align="center">
          {title}
        </Typography>
        <Box p={1} />
        <Typography variant="body1" align="center" style={{ color: '#425466' }}>
          {subtitle}
        </Typography>
        <Box p={5} />
      </>
    );
  };

  const Timeline = ({ month, date, title, subtitle, status, due }) => {
    let bgColor, statusbarColor, statusLabel;
    switch (status) {
      case 'Pending Review':
        bgColor = '#E3EDFD';
        statusbarColor = '#ACD4FC';
        break;
      case 'Approved':
        bgColor = '#E0F4E4';
        statusbarColor = '#92E6AF';
        break;
      case 'In Progress':
        bgColor = '#FFF3CC';
        statusbarColor = '#FFE48E';
        break;
      case 'Rejected':
        bgColor = '#FFEBE8';
        statusbarColor = '#FFA29F';
    }
    var daysLeftShow = false;
    if (status === 'Pending Review') {
      statusLabel = 'Resubmitted';

      if (due > 0) {
        daysLeftShow = true;
      }
    }
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          background: bgColor,
          borderRadius: 10,
          paddingBottom: 'unset',
        }}
        iconStyle={{ size: 1, background: '#e4e4e4', color: '#fff' }}
      >
        <Box
          display="flex"
          alignItems={smBelow ? 'center' : 'flex-start'}
          flexDirection={smBelow ? 'column' : 'row'}
          style={{ marginBottom: '-40px' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            style={{
              backgroundColor: 'white',
              width: 80,
              height: 70,
              borderRadius: 10,
            }}
          >
            <Typography
              variant="h6"
              align="center"
              style={{ color: '#808191' }}
            >
              {month}
            </Typography>
            <Typography
              variant="h1"
              align="center"
              style={{ fontWeight: 600, color: '#000000' }}
            >
              {date}
            </Typography>
          </Box>
          <Box p={1} />
          <Container>
            <Typography
              variant="h4"
              align={smBelow ? 'center' : 'left'}
              style={{ fontWeight: 600, color: 'black' }}
            >
              {title}
            </Typography>
            {subtitle && (
              <>
                <Box p={1} />
                <Typography
                  variant="h5"
                  align={smBelow ? 'center' : 'left'}
                  style={{ fontWeight: 600, color: '#808191' }}
                >
                  {subtitle}
                </Typography>
              </>
            )}

            <Box
              display="flex"
              flexDirection={smBelow ? 'column' : 'row'}
              alignItems="center"
            >
              {/* {progress.map((item, index) => ( */}
              <>
                <Box py={smBelow ? 1 : 3}>
                  <Typography
                    variant="h6"
                    align="center"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: statusbarColor,
                      borderRadius: 15,
                      padding: 10,
                      width: 150,
                      height: 30,
                    }}
                  >
                    {statusLabel ? statusLabel : status}
                    {/* {item.icon && (
                      <>
                        {item.icon}
                        <Box pr={1} />
                      </>
                    )}
                    {item.date} */}
                  </Typography>
                  <Box p={smBelow ? 0 : 1} />
                </Box>
                <Box p={smBelow ? 0 : 1} />
                {daysLeftShow ? (
                  <>
                    <Box py={smBelow ? 1 : 3}>
                      <Typography
                        variant="h6"
                        align="center"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: statusbarColor,
                          borderRadius: 15,
                          padding: 10,
                          width: 150,
                          height: 30,
                        }}
                      >
                        {<AccessTimeOutlinedIcon />}
                        {due + ' DAYS LEFT'}
                        {/* {item.icon && (
                      <>
                        {item.icon}
                        <Box pr={1} />
                      </>
                    )}
                    {item.date} */}
                      </Typography>
                      <Box p={smBelow ? 0 : 1} />
                    </Box>
                    <Box p={smBelow ? 0 : 1} />
                  </>
                ) : null}
              </>
              {/* ))} */}
              <Box p={2} />
            </Box>
          </Container>
        </Box>
      </VerticalTimelineElement>
    );
  };

  const UpcomingTimeline = () => {
    return (
      <Paper className={classes.upcomingPaper}>
        <Box p={3} px={5}>
          <Typography variant="h3" className={classes.fontWeight}>
            Upcoming
          </Typography>
          <Box p={1} />
          {!loading ? (
            <>
              {upcomingList.length !== 0 ? (
                <>
                  {upcomingList.map((item, index) => (
                    <>
                      <Paper
                        style={{
                          padding: 20,
                          backgroundColor: '#BCE2FF',
                          borderRadius: 10,
                          display: 'flex',
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          style={{
                            backgroundColor: 'white',
                            width: 80,
                            height: 70,
                            borderRadius: 10,
                          }}
                        >
                          <Typography
                            variant="h6"
                            align="center"
                            style={{ color: '#808191' }}
                          >
                            {new Date(item.event_datetime)
                              .toLocaleString('default', {
                                month: 'short',
                              })
                              .toUpperCase()}
                          </Typography>
                          <Typography
                            variant="h1"
                            align="center"
                            style={{ fontWeight: 600, color: '#000000' }}
                          >
                            {new Date(item.event_datetime).getDate()}
                          </Typography>
                        </Box>
                        <Box pr={1} />
                        <Container>
                          <Typography
                            variant="h4"
                            align={smBelow ? 'center' : 'left'}
                            style={{ fontWeight: 600, color: 'black' }}
                          >
                            {item.event_name}
                          </Typography>

                          {item.countdown_days && (
                            <>
                              <Box p={1} />
                              <Typography
                                variant="h5"
                                align={smBelow ? 'center' : 'left'}
                                style={{ color: '#808191' }}
                                display="inline"
                              >
                                Coming up&nbsp;
                                <Typography
                                  variant="h5"
                                  align={smBelow ? 'center' : 'left'}
                                  style={{ color: '#040C22' }}
                                  display="inline"
                                >
                                  in {item.countdown_days} days
                                </Typography>
                              </Typography>
                            </>
                          )}
                        </Container>
                      </Paper>

                      <Box p={1} />
                    </>
                  ))}
                </>
              ) : (
                renderEmptyTimeline(
                  'You have no upcomings!',
                  'If you will get any upcomings we will tell you about it.',
                )
              )}
            </>
          ) : (
            <div
              style={{
                width: '100%',
              }}
            >
              <EnhancedSkeleton
                layout={[
                  {
                    loaders: [{ variant: 'rect', height: 50, width: '100%' }],
                    repeat: 5,
                    mt: 4.25,
                  },
                ]}
              />
            </div>
          )}
        </Box>
      </Paper>
    );
  };

  return (
    <>
      <DashLayout>
        <Box
          display="flex"
          flexDirection={smBelow ? 'column' : 'row'}
          width="100%"
        >
          <InfoBox vertical center width={smBelow ? '100% ' : '35%'} />
          <Box
            pt={smBelow ? 3 : 0}
            pl={smBelow ? 0 : 3}
            width={smBelow ? '100% ' : '65%'}
          >
            <UpcomingTimeline />
          </Box>
        </Box>

        <Box p={2} />
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
            padding: smBelow ? 0 : 40,
            paddingTop: smBelow ? 20 : 40,
            paddingBottom: smBelow ? 20 : 40,
          }}
          elevation={4}
        >
          <Box
            display="flex"
            flexDirection={smBelow ? 'column' : 'row'}
            width="100%"
            alignItems="center"
          >
            <Typography variant="h3" className={classes.fontWeight}>
              Company Compliance Timeline
            </Typography>
            <Box p={1} />
            <div style={{ flexGrow: 1 }} />
            <Select
              value={year}
              onChange={(event) => {
                setYear(event.target.value);
              }}
              IconComponent={() => <ExpandMore />}
              disableUnderline
              style={{
                backgroundColor: '#E4E4E4',
                padding: 10,
                borderRadius: 5,
              }}
            >
              <MenuItem value={2021}>Year 2021</MenuItem>
              <MenuItem value={2020}>Year 2020</MenuItem>
              <MenuItem value={2019}>Year 2019</MenuItem>
            </Select>
          </Box>

          {!tlLoading ? (
            <>
              {yearTimeline.length !== 0 ? (
                <VerticalTimeline
                  className="vertical-timeline-custom-line"
                  layout={'1-columns'}
                >
                  {renderTimeline(yearTimeline)}
                </VerticalTimeline>
              ) : (
                renderEmptyTimeline(
                  'You have no active work!',
                  'If you will get any work we will tell you about it.',
                )
              )}
            </>
          ) : (
            <div
              style={{
                width: '100%',
              }}
            >
              <EnhancedSkeleton
                layout={[
                  {
                    loaders: [{ variant: 'rect', height: 50, width: '100%' }],
                    repeat: 5,
                    mt: 4.25,
                  },
                ]}
              />
            </div>
          )}
        </Paper>
      </DashLayout>
    </>
  );
};

export default Dashboard;
// import Paper from '@material-ui/core/Paper';
// import { makeStyles } from '@material-ui/core/styles';
// import { Select, MenuItem, Typography, Container } from '@material-ui/core';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
// import BusinessIcon from '@material-ui/icons/Business';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import RepeatIcon from '@material-ui/icons/Repeat';
// import Timeline from '@material-ui/lab/Timeline';
// import TimelineConnector from '@material-ui/lab/TimelineConnector';
// import TimelineContent from '@material-ui/lab/TimelineContent';
// import TimelineDot from '@material-ui/lab/TimelineDot';
// import TimelineItem from '@material-ui/lab/TimelineItem';
// import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
// import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
// import moment from 'moment';
// import React, { useEffect, useState } from 'react';
// import { EnhancedSkeleton } from 'src/components/Enhanced';
// import timelineSvc from 'src/services/timeline';
// import InfoBox from 'src/components/InfoBox';
// import {
//   VerticalTimeline,
//   VerticalTimelineElement,
// } from 'react-vertical-timeline-component';
// import SchoolIcon from '@material-ui/icons/School';
// import WorkIcon from '@material-ui/icons/Work';
// import StarIcon from '@material-ui/icons/Star';
// import 'react-vertical-timeline-component/style.min.css';
// import { Box } from '@material-ui/core';
// import './styles.css';
// import useStyles from './Dashboard.styles';
// import DashLayout from 'src/components/Corpsec/Dashboard/Layout';
// import useBreakpoints from 'src/hooks/useBreakpoints';

// const Dashboard = () => {
//   const classes = useStyles();
//   const { smBelow } = useBreakpoints();
//   const [timeline, setTimeline] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [year, setYear] = React.useState('2021');

//   useEffect(() => {
//     (async () => {
//       try {
//         const data = await timelineSvc.timeline();
//         if (data) setTimeline(data.reverse());
//         setLoading(false);
//       } catch (err) {
//         setLoading(false);
//       }
//     })();
//   }, []);

//   const Timeline = ({
//     month,
//     date,
//     title,
//     subtitle,
//     progress,
//     bgColor,
//     statusbarColor,
//   }) => {
//     return (
//       <VerticalTimelineElement
//         className="vertical-timeline-element--work"
//         contentStyle={{
//           background: bgColor,
//           borderRadius: 10,
//           paddingBottom: 'unset',
//         }}
//         iconStyle={{ size: 1, background: '#e4e4e4', color: '#fff' }}
//       >
//         <Box
//           display="flex"
//           alignItems={smBelow ? 'center' : 'flex-start'}
//           flexDirection={smBelow ? 'column' : 'row'}
//           style={{ marginBottom: '-40px' }}
//         >
//           <Box
//             display="flex"
//             flexDirection="column"
//             justifyContent="center"
//             style={{
//               backgroundColor: 'white',
//               width: 80,
//               height: 90,
//               borderRadius: 10,
//             }}
//           >
//             <Typography
//               variant="h6"
//               align="center"
//               style={{ color: '#808191' }}
//             >
//               {month}
//             </Typography>
//             <Typography
//               variant="h1"
//               align="center"
//               style={{ fontWeight: 600, color: '#000000' }}
//             >
//               {date}
//             </Typography>
//           </Box>
//           <Box p={1} />
//           <Container>
//             <Typography
//               variant="h4"
//               align={smBelow ? 'center' : 'left'}
//               style={{ fontWeight: 600, color: 'black' }}
//             >
//               {title}
//             </Typography>
//             {subtitle && (
//               <>
//                 <Box p={1} />
//                 <Typography
//                   variant="h5"
//                   align={smBelow ? 'center' : 'left'}
//                   style={{ fontWeight: 600, color: '#808191' }}
//                 >
//                   {subtitle}
//                 </Typography>
//               </>
//             )}

//             <Box
//               display="flex"
//               flexDirection={smBelow ? 'column' : 'row'}
//               alignItems="center"
//             >
//               {progress.map((item, index) => (
//                 <>
//                   <Box py={smBelow ? 1 : 3}>
//                     <Typography
//                       variant="h6"
//                       align="center"
//                       style={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         backgroundColor: statusbarColor,
//                         borderRadius: 15,
//                         padding: 10,
//                         width: 180,
//                       }}
//                     >
//                       {item.status}
//                       {item.icon && (
//                         <>
//                           {item.icon}
//                           <Box pr={1} />
//                         </>
//                       )}
//                       {item.date}
//                     </Typography>
//                     <Box p={smBelow ? 0 : 1} />
//                   </Box>
//                   <Box p={smBelow ? 0 : 1} />
//                 </>
//               ))}
//               <Box p={2} />
//             </Box>
//           </Container>
//         </Box>
//       </VerticalTimelineElement>
//     );
//   };

//   return (
//     <>
//       <DashLayout>
//         <InfoBox />
//         <Box p={2} />
//         <Paper
//           style={{
//             width: '100%',
//             borderRadius: 10,
//             padding: smBelow ? 0 : 40,
//             paddingTop: smBelow ? 20 : 40,
//             paddingBottom: smBelow ? 20 : 40,
//           }}
//           elevation={4}
//         >
//           <Box
//             display="flex"
//             flexDirection={smBelow ? 'column' : 'row'}
//             width="100%"
//             alignItems="center"
//           >
//             <Typography variant="h3" className={classes.fontWeight}>
//               Company Compliance Timeline
//             </Typography>
//             <Box p={1} />
//             <div style={{ flexGrow: 1 }} />
//             <Select
//               value={year}
//               onChange={(event) => setYear(event.target.value)}
//               IconComponent={() => <ExpandMore />}
//               disableUnderline
//               style={{
//                 backgroundColor: '#E4E4E4',
//                 padding: 10,
//                 borderRadius: 5,
//               }}
//             >
//               <MenuItem value={2021}>Year 2021</MenuItem>
//               <MenuItem value={2020}>Year 2020</MenuItem>
//               <MenuItem value={2019}>Year 2019</MenuItem>
//             </Select>
//           </Box>

//           <VerticalTimeline
//             className="vertical-timeline-custom-line"
//             layout={'1-columns'}
//           >
//             <Timeline
//               title="Filing of Register of Registrable Controllers (RORC)"
//               subtitle="Register must be filed within 30 days of date of incorporation."
//               month="DEC"
//               date="18"
//               progress={[
//                 {
//                   status: 'PENDING REVIEW',
//                 },
//                 {
//                   icon: <AccessTimeOutlinedIcon />,
//                   date: '28 DAYS LEFT',
//                 },
//               ]}
//               bgColor="#E3EDFD"
//               statusbarColor="#ACD4FC"
//             />
//             <Timeline
//               title="Incorporation [Sec 19(4)]"
//               month="NOV"
//               date="15"
//               progress={[
//                 {
//                   status: 'APPROVED',
//                 },
//               ]}
//               bgColor="#E0F4E4"
//               statusbarColor="#92E6AF"
//             />
//             <Timeline
//               title="Incorporation [Sec 19(4)]"
//               month="NOV"
//               date="03"
//               progress={[
//                 {
//                   status: 'IN PROGRESS',
//                 },
//               ]}
//               bgColor="#FFF3CC"
//               statusbarColor="#FFE48E"
//             />
//             <Timeline
//               title="Application of New Company Name"
//               subtitle="Company name will be reserved for 120 days before expiring."
//               month="OCT"
//               date="11"
//               progress={[
//                 {
//                   status: 'APPROVED',
//                 },
//               ]}
//               bgColor="#E0F4E4"
//               statusbarColor="#92E6AF"
//             />
//             <Timeline
//               title="Application of New Company Name"
//               subtitle="Company name will be reserved for 120 days before expiring."
//               month="OCT"
//               date="04"
//               progress={[
//                 {
//                   status: 'IN PROGRESS',
//                 },
//               ]}
//               bgColor="#FFF3CC"
//               statusbarColor="#FFE48E"
//             />
//           </VerticalTimeline>
//         </Paper>
//       </DashLayout>
//     </>
//   );
// };

// export default Dashboard;
