import React, { useState, useEffect, useRef } from 'react';
import './message.css';
import {
  Typography,
  Box,
  Button,
  ButtonBase,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  InputAdornment,
  Radio,
  FormControlLabel,
  Avatar,
} from '@material-ui/core';
import ROUTES from '../constants/routes';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import useBreakpoints from '../hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import ContentLayout from 'src/components/ContentLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import settingsbar from '../assets/settingsbar.png';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { useProvideData } from 'src/hooks/useData';
import RadioButton from 'src/components/RadioButton';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import IconActionList from 'src/components/IconActionList';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import OtpInput from 'react-otp-input';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useProvideSysInfoUserTable } from 'src/hooks/useSysInfoUser';
import { useProvideOtp } from 'src/hooks/useOtp';
import ButtonModal from 'src/components/ButtonModal';
import authSvc from 'src/services/authentication';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 600,
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
    backgroundPosition: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  bgroot: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    zIndex: 0,
    height: '20vh',
  },
  tab: {
    textTransform: 'initial',
    fontSize: 16,
  },
  disabledText: {
    paddingLeft: '5px',
  },
  changeDetails: {
    color: '#888B94',
  },
}));

const accounts = [
  {
    name: 'MTSG Singapore ',
    id: 'C_1K7dsflknklsdfjlaj',
    role: 'Administrator (Owner)',
  },
  {
    name: 'ABC Company',
    id: 'C_1K7dsflknklsdfjlaj',
    role: 'Shareholder',
  },
  {
    name: 'XYZ Company',
    id: 'C_1K7dsflknklsdfjlaj',
    role: 'Administrator (Owner)',
  },
  {
    name: 'Techlink Company',
    id: 'C_1K7dsflknklsdfjlaj',
    role: 'Shareholder',
  },
];

const Settings = () => {
  const classes = useStyles();
  const { mdBelow } = useBreakpoints();
  const [tabValue, setTabValue] = useState(0);
  const [gender, setGender] = React.useState('1');
  const [smsSwitch, setSmsSwitch] = React.useState();
  const [changeNumber, setChangeNumber] = React.useState(false);
  const [changeEmail, setChangeEmail] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  const [changePersonalInfo, setChangePersonalInfo] = React.useState(false);
  const [changeContactAddress, setChangeContactAddress] = React.useState(false);
  const [passwordUpdate, setPasswordUpdate] = React.useState(false);
  const [smsVerify, setSmsVerify] = React.useState(false);
  const [infoUpdated, setInfoUpdated] = React.useState(false);
  const [countryCode, setCountryCode] = useState('SG');
  const [countryPhone, setCountryPhone] = useState('+65');
  const [otp, setOtp] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState({
    phone_number: '',
    verified: false,
  });
  const [emailAddress, setEmailAddress] = React.useState({
    email_address: '',
    verified: false,
  });
  const [personalInfo, setPersonalInfo] = React.useState({
    first_name: '',
    last_name: '',
    gender_id: '',
    nationality_id: '',
    verified: false,
  });
  const [contactAddress, setContactAddress] = React.useState({
    country_id: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    postal_code: '',
    verified: false,
  });
  const [user, setUser] = useState('');
  let stepformRef = useRef(null);

  const { countries, ListCountries } = useProvideData();
  const {
    loading,
    sysInfoUser,
    ListSysInfoUser,
    UpdateSysInfoUser,
  } = useProvideSysInfoUserTable();
  const { validatingOtp, validateOtpResult, ValidateOtp } = useProvideOtp();

  const verifyOtp = () => {
    ValidateOtp({
      otp: 'abc1234',
      otp_type: 'email',
      expiration_time: null,
      created_by: 1,
    });
  };
  console.log('@@@ validateOtpResult', validateOtpResult);

  useEffect(() => {
    const data = authSvc.getAuthData();
    setUser(data.user);
  }, []);

  useEffect(() => {
    ListSysInfoUser(user.sysInfo_user_id);
  }, [user.sysInfo_user_id]);

  useEffect(() => {
    setSmsSwitch(sysInfoUser.is_2fa_enabled);
  }, [sysInfoUser]);

  const personalInfoValidationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    nationality_id: Yup.string().required('Nationality is required'),
  });

  const contactAddressValidationSchema = Yup.object({
    country_id: Yup.string().required('Country of Residence is required'),
    address_line_1: Yup.string().required('Address is required'),
    address_line_3: Yup.string().required('State is required'),
    postal_code: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Postal Code is required'),
  });

  const numberValidationSchema = Yup.object({
    phone_number: Yup.string().required('Phone Number is required'),
    password: Yup.string().required('Password is required'),
  });

  const emailValidationSchema = Yup.object({
    new_email: Yup.string()
      .required('New Email is required')
      .email('Must be a valid email address'),
    confirm_email: Yup.string()
      .required('Confirm Email is required')
      .email('Must be a valid email address')
      .oneOf([Yup.ref('new_email'), null], 'Email must match'),
  });

  const passwordValidationSchema = Yup.object({
    current_password: Yup.string().required('Current Password is required'),
    new_password: Yup.string().required('New Password required'),
    confirm_password: Yup.string()
      .required('Confirm Password required')
      .oneOf([Yup.ref('new_password'), null], 'Password must match'),
  });

  useEffect(() => {
    ListCountries();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getDefaultCountry = (countryID) => {
    return countries.find((country) => country.country_id === countryID);
  };

  const InfoEditBox = ({ label, isVerified, onPressEdit, children }) => {
    return (
      <Paper elevation={0} variant="outlined" style={{ width: '100%' }}>
        <Box
          p={3}
          pl={5}
          display="flex"
          flexDirection={mdBelow ? 'column' : 'row'}
          alignItems="center"
          style={{ backgroundColor: '#F0F3F6' }}
        >
          <Box display={mdBelow ? 'flex' : 'contents'}>
            <Typography variant="h4" align="left">
              {label}
            </Typography>
            {isVerified && (
              <>
                <Box p={1} />
                <Typography
                  variant="caption"
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 5,
                    color: 'white',
                    backgroundColor: '#43D249',
                    height: 18,
                  }}
                >
                  Verified
                </Typography>
              </>
            )}
            {!mdBelow && (
              <>
                <div style={{ flexGrow: 1 }} />
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  style={{
                    textTransform: 'initial',
                    width: '8%',
                  }}
                  onClick={onPressEdit}
                >
                  Edit
                </Button>
              </>
            )}
          </Box>
          {mdBelow && (
            <Box pt={2} width="100%" display="flex" justifyContent="center">
              <Button
                variant="contained"
                size="large"
                color="primary"
                style={{
                  textTransform: 'initial',
                  width: '30%',
                }}
                onClick={onPressEdit}
              >
                Edit
              </Button>
            </Box>
          )}
        </Box>
        <List subheader={<li />}>
          <Typography variant="h4">{children}</Typography>
        </List>
      </Paper>
    );
  };

  const renderProfileTab = () => {
    return (
      <>
        <ButtonModal
          isModalOpen={changePersonalInfo}
          onCloseModal={() => setChangePersonalInfo(false)}
          label={!smsVerify ? 'Edit Personal Info' : 'OTP Verification'}
          buttonLabel2={!smsVerify ? 'Update' : 'Next'}
          modalHeight={infoUpdated ? '25%' : '60%'}
          onPress1={() => {
            setChangePersonalInfo(false);
          }}
          onPress2={() => {
            if (!smsVerify) {
              submitForm();
            } else {
              UpdateSysInfoUser(user.user_id, {
                first_name: personalInfo.first_name,
                last_name: personalInfo.last_name,
                gender_id: personalInfo.gender_id,
                nationality_id: personalInfo.nationality_id,
              });
              setPersonalInfo({ ...personalInfo, verified: true });
              setInfoUpdated(true);
            }
          }}
          hideLabel={infoUpdated ? true : false}
          hideButtons={infoUpdated ? true : false}
        >
          {!smsVerify && renderPersonalInfoForm()}
          {smsVerify && renderSmsVerify(setChangePersonalInfo)}
        </ButtonModal>
        <ButtonModal
          isModalOpen={changeContactAddress}
          onCloseModal={() => setChangeContactAddress(false)}
          label={!smsVerify ? 'Edit Contact Address' : 'OTP Verification'}
          buttonLabel2={!smsVerify ? 'Update' : 'Next'}
          modalHeight={infoUpdated ? '25%' : '80%'}
          onPress1={() => {
            setChangeContactAddress(false);
          }}
          onPress2={() => {
            if (!smsVerify) {
              submitForm();
            } else {
              UpdateSysInfoUser(user.user_id, {
                country_id: contactAddress.country_id,
                address_line_1: contactAddress.address_line_1,
                address_line_2: contactAddress.address_line_2,
                address_line_3: contactAddress.address_line_3,
                postal_code: contactAddress.postal_code,
              });
              setContactAddress({ ...contactAddress, verified: true });
              setInfoUpdated(true);
            }
          }}
          hideLabel={infoUpdated ? true : false}
          hideButtons={infoUpdated ? true : false}
        >
          {!smsVerify && renderContactAddressForm()}
          {smsVerify && renderSmsVerify(setChangeContactAddress)}
        </ButtonModal>
        <InfoEditBox
          label="Personal Info"
          isVerified={sysInfoUser.is_personalinfo_verified > 0}
          onPressEdit={() => {
            setChangePersonalInfo(true);
            setSmsVerify(false);
          }}
        >
          {renderPersonalInfo()}
        </InfoEditBox>
        <Box p={2} />

        <InfoEditBox
          label="Contact Address"
          isVerified={sysInfoUser.is_address_verified > 0}
          onPressEdit={() => {
            setChangeContactAddress(true);
            setSmsVerify(false);
          }}
        >
          {renderContactAddress()}
        </InfoEditBox>
        <Box p={2} />

        <Paper elevation={0} variant="outlined">
          <List subheader={<li />}>
            <Box
              p={3}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <Typography
                variant="body1"
                align={mdBelow ? 'left' : 'right'}
                className={classes.changeDetails}
              >
                Would you like to change your details?
              </Typography>
              <Typography
                variant="body1"
                align={mdBelow ? 'left' : 'right'}
                display="inline"
                className={classes.changeDetails}
              >
                Please contact us at&nbsp;
                <ButtonBase onClick={() => {}} style={{ paddingBottom: 3 }}>
                  <Typography
                    variant="body1"
                    align="right"
                    display="inline"
                    color="primary"
                  >
                    support@meyzer360.com
                  </Typography>
                </ButtonBase>
              </Typography>
            </Box>
          </List>
        </Paper>

        <Box p={2} />
        {renderCompanyAccount()}
      </>
    );
  };

  const personalInfoFormikOnSubmit = async (values, actions) => {
    setPersonalInfo({
      first_name: values.first_name,
      last_name: values.last_name,
      gender_id: gender,
      nationality_id: values.nationality_id,
    });
    setSmsVerify(true);
  };

  const contactAddressFormikOnSubmit = async (values, actions) => {
    setContactAddress({
      country_id: values.country_id,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      address_line_3: values.address_line_3,
      postal_code: values.postal_code,
    });
    setSmsVerify(true);
  };

  const numberFormikOnSubmit = async (values, actions) => {
    setSmsVerify(true);
  };

  const emailFormikOnSubmit = async (values, actions) => {
    setSmsVerify(true);
  };

  const passwordFormikOnSubmit = async (values, actions) => {
    setPasswordUpdate(true);
  };

  const RowField = ({ leftComponent, rightComponent }) => {
    return (
      <Box display="flex" flexDirection={mdBelow ? 'column' : 'row'}>
        {leftComponent}
        {!mdBelow && <Box p={5} />}
        {rightComponent}
      </Box>
    );
  };

  const ViewTextField = ({ label, value, locked }) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width={mdBelow ? '100%' : '20%'}
      >
        <EnhancedTextField
          disabled
          labelText={label}
          value={value ? value : '-'}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <>
                {locked && (
                  <InputAdornment position="end">
                    <LockOutlinedIcon />
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      </Box>
    );
  };

  const getGenderName = (genderId) => {
    return genderId == '0' ? 'Female' : 'Male';
  };

  const getCountryName = (id) => {
    let name = countries.find((country) => country.country_id === id);
    if (name) return name.country_name;
  };

  const renderPersonalInfo = () => {
    return (
      <>
        {!loading ? (
          <>
            <Box p={5} pb={0}>
              <RowField
                leftComponent={
                  <ViewTextField
                    label="First name as shown on your ID "
                    value={
                      personalInfo.verified
                        ? personalInfo.first_name
                        : sysInfoUser.first_name
                    }
                  />
                }
                rightComponent={
                  <ViewTextField
                    label="Last name as shown on your ID"
                    value={
                      personalInfo.verified
                        ? personalInfo.last_name
                        : sysInfoUser.last_name
                    }
                  />
                }
              />

              <RowField
                leftComponent={
                  <ViewTextField
                    label="Gender"
                    value={getGenderName(
                      personalInfo.verified
                        ? personalInfo.gender_id
                        : sysInfoUser.gender_id,
                    )}
                  />
                }
                rightComponent={
                  <ViewTextField
                    label="Nationality"
                    value={getCountryName(
                      personalInfo.verified
                        ? personalInfo.nationality_id
                        : sysInfoUser.nationality_id,
                    )}
                  />
                }
              />

              <RowField
                leftComponent={
                  <ViewTextField
                    label="Date of birth"
                    value={sysInfoUser.dob?.substring(0, 10)}
                    locked
                  />
                }
                rightComponent={
                  <ViewTextField
                    label="Country of birth"
                    value={sysInfoUser.birth_country}
                    locked
                  />
                }
              />
            </Box>
          </>
        ) : (
          <div
            style={{
              width: '100%',
            }}
          >
            <EnhancedSkeleton
              layout={[
                {
                  loaders: [{ variant: 'rect', height: 50, width: '100%' }],
                  repeat: 5,
                  mt: 4.25,
                },
              ]}
            />
          </div>
        )}
      </>
    );
  };

  const renderContactAddress = () => {
    return (
      <>
        {!loading ? (
          <>
            <Box p={5} pb={0}>
              <RowField
                leftComponent={
                  <ViewTextField
                    label="Country of residence"
                    value={getCountryName(
                      contactAddress.verified
                        ? contactAddress.country_id
                        : sysInfoUser.country_id,
                    )}
                  />
                }
                rightComponent={
                  <ViewTextField
                    label="Postal code"
                    value={
                      contactAddress.verified
                        ? contactAddress.postal_code
                        : sysInfoUser.postal_code
                    }
                  />
                }
              />

              <RowField
                leftComponent={
                  <ViewTextField
                    label="Address line 1"
                    value={
                      contactAddress.verified
                        ? contactAddress.address_line_1
                        : sysInfoUser.address_line_1
                    }
                  />
                }
                rightComponent={
                  <ViewTextField
                    label="Address line 2 (Optional)"
                    value={
                      contactAddress.verified
                        ? contactAddress.address_line_2
                        : sysInfoUser.address_line_2
                    }
                  />
                }
              />

              <ViewTextField
                label="City / State"
                value={
                  contactAddress.verified
                    ? contactAddress.address_line_3
                    : sysInfoUser.address_line_3
                }
              />
            </Box>
          </>
        ) : (
          <div
            style={{
              width: '100%',
            }}
          >
            <EnhancedSkeleton
              layout={[
                {
                  loaders: [{ variant: 'rect', height: 50, width: '100%' }],
                  repeat: 5,
                  mt: 4.25,
                },
              ]}
            />
          </div>
        )}
      </>
    );
  };

  const renderCompanyAccount = () => {
    return (
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          borderRadius: '10px',
        }}
      >
        <List subheader={<li />}>
          <Box p={2}>
            <Typography variant="h4">Company Account</Typography>
            <Typography variant="body1">
              The list of accounts to which you are a member.
            </Typography>
          </Box>
          <ListItem
            style={{
              backgroundColor: '#F5F5F5',
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                style: {
                  whiteSpace: 'normal',
                },
              }}
              primary={
                <Box display="flex" style={{ width: '100%' }}>
                  <Typography style={{ flex: '1 1 0%' }} variant="h5">
                    COMPANY ACCOUNT NAME
                  </Typography>
                  <Typography style={{ flex: '1 1 0%' }} variant="h5">
                    ID
                  </Typography>
                  {!mdBelow && (
                    <Typography style={{ flex: '1 1 0%' }} variant="h5">
                      ROLE
                    </Typography>
                  )}
                </Box>
              }
            />
          </ListItem>
          <Divider />
          {accounts.length > 0 &&
            accounts.map((item, index) => (
              <>
                <ListItem key={`${item.id}${index}`}>
                  <ListItemText
                    style={{ padding: 10 }}
                    primary={
                      <Box display="flex" style={{ width: '100%' }}>
                        <Typography
                          style={{ flex: '1 1 0%' }}
                          variant="body1"
                          className={classes.fontWeight}
                        >
                          {item.name}
                        </Typography>
                        <Typography style={{ flex: '1 1 0%' }} variant="body1">
                          {item.id}
                        </Typography>
                        {!mdBelow && (
                          <Typography
                            style={{ flex: '1 1 0%' }}
                            variant="body1"
                          >
                            {item.role}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </ListItem>
                {index + 1 !== accounts.length && <Divider />}
              </>
            ))}
        </List>
      </Paper>
    );
  };

  const renderPersonalInfoForm = () => {
    return (
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          nationality_id: '',
        }}
        onSubmit={personalInfoFormikOnSubmit}
        validationSchema={personalInfoValidationSchema}
        innerRef={(ref) => (stepformRef = ref)}
      >
        {(props) => {
          return (
            <Form>
              <Box px={4}>
                <FormTextField
                  name="first_name"
                  labelText="First name as shown on your ID "
                  shrink
                  variant="outlined"
                  width={mdBelow ? '100%' : '50%'}
                />
                <FormTextField
                  name="last_name"
                  labelText="Last name as shown on your ID"
                  shrink
                  variant="outlined"
                  width={mdBelow ? '100%' : '50%'}
                />
                <RadioButton
                  name="gender_id"
                  label="Gender"
                  value={gender}
                  onChange={(event) => {
                    setGender(event.target.value);
                  }}
                  child={
                    <>
                      <FormControlLabel
                        value={'1'}
                        control={<Radio color="primary" />}
                        label="Male"
                      />
                      <FormControlLabel
                        value={'0'}
                        control={<Radio color="primary" />}
                        label="Female"
                      />
                    </>
                  }
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  width={mdBelow ? '100%' : '50%'}
                >
                  <FormDropdown
                    autoCompleteStyle={{ width: '100%' }}
                    name="nationality_id"
                    onChange={(event, newValue) => {
                      props.setFieldValue('nationality_id', newValue?.value);
                    }}
                    optionList={countries}
                    labelText="Nationality"
                    placeholder="Select nationality"
                    fullWidth
                    shrink
                    variant="outlined"
                  />
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderContactAddressForm = () => {
    return (
      <Formik
        initialValues={{
          country_id: '',
          postal_code: '',
          address_line_1: '',
          address_line_2: '',
          address_line_3: '',
        }}
        onSubmit={contactAddressFormikOnSubmit}
        validationSchema={contactAddressValidationSchema}
        innerRef={(ref) => (stepformRef = ref)}
      >
        {(props) => {
          return (
            <Form>
              <Box px={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={mdBelow ? '100%' : '50%'}
                >
                  <FormDropdown
                    autoCompleteStyle={{ width: '100%' }}
                    name="country_id"
                    onChange={(event, newValue) => {
                      props.setFieldValue('country_id', newValue?.value);
                    }}
                    optionList={countries}
                    labelText="Country of residence"
                    placeholder="Select country"
                    fullWidth
                    shrink
                    variant="outlined"
                  />
                </Box>
                <FormTextField
                  name="postal_code"
                  labelText="Postal code"
                  placeholder="123456"
                  shrink
                  variant="outlined"
                  width={mdBelow ? '100%' : '50%'}
                />
                <FormTextField
                  name="address_line_1"
                  labelText="Address line 1"
                  placeholder="Enter your address"
                  shrink
                  variant="outlined"
                  width={mdBelow ? '100%' : '50%'}
                />
                <FormTextField
                  name="address_line_2"
                  labelText="Address line 2 (Optional)"
                  placeholder="Apartment, suite, unit, floor, etc."
                  shrink
                  variant="outlined"
                  width={mdBelow ? '100%' : '50%'}
                />
                <FormTextField
                  name="address_line_3"
                  labelText="City / State"
                  placeholder="Enter your city / state"
                  shrink
                  variant="outlined"
                  width={mdBelow ? '100%' : '50%'}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderNumberForm = () => {
    return (
      <Formik
        initialValues={{
          phone_number: '',
          password: '',
        }}
        onSubmit={numberFormikOnSubmit}
        validationSchema={numberValidationSchema}
        innerRef={(ref) => (stepformRef = ref)}
      >
        {(props) => {
          return (
            <Form>
              <Box px={4}>
                <FormDropdownTextField
                  name="phone_number"
                  labelText="Phone number"
                  placeholder="91234567"
                  containerWidth={mdBelow ? '100%' : '50%'}
                  fullWidth
                  shrink
                  variant="outlined"
                  showFlag={false}
                  onValueChange={(event, newValue) => {
                    setCountryCode(newValue.code);
                    setCountryPhone(newValue.phone);
                  }}
                  countryCode={countryCode}
                  onChange={(event) => {
                    setPhoneNumber({
                      phone_number: countryPhone + event.target.value,
                    });
                    props.setFieldValue('phone_number', event.target.value);
                  }}
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                <FormTextField
                  name="password"
                  labelText="Password"
                  placeholder="******"
                  type={showPassword ? 'text' : 'password'}
                  shrink
                  variant="outlined"
                  fullWidth={mdBelow ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderEmailForm = () => {
    return (
      <Formik
        initialValues={{
          new_email: '',
          confirm_email: '',
        }}
        onSubmit={emailFormikOnSubmit}
        validationSchema={emailValidationSchema}
        innerRef={(ref) => (stepformRef = ref)}
      >
        {(props) => {
          return (
            <Form>
              <Box px={4}>
                <FormTextField
                  name="new_email"
                  labelText="New Email Address"
                  placeholder="test@email.com"
                  shrink
                  variant="outlined"
                  fullWidth={mdBelow ? true : false}
                />
                <FormTextField
                  name="confirm_email"
                  labelText="Confirm Email Address"
                  placeholder="test@email.com"
                  shrink
                  variant="outlined"
                  fullWidth={mdBelow ? true : false}
                  onChange={(event) => {
                    setEmailAddress({ email_address: event.target.value });
                    props.setFieldValue('confirm_email', event.target.value);
                  }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderPasswordForm = () => {
    return (
      <Formik
        initialValues={{
          current_password: '',
          new_password: '',
          confirm_password: '',
        }}
        onSubmit={passwordFormikOnSubmit}
        validationSchema={passwordValidationSchema}
        innerRef={(ref) => (stepformRef = ref)}
      >
        {(props) => {
          return (
            <Form>
              <Box px={4}>
                <FormTextField
                  name="current_password"
                  labelText="Current Password"
                  type="password"
                  shrink
                  variant="outlined"
                  fullWidth={mdBelow ? true : false}
                />
                <FormTextField
                  name="new_password"
                  labelText="New Password"
                  type="password"
                  shrink
                  variant="outlined"
                  fullWidth={mdBelow ? true : false}
                />
                <FormTextField
                  name="confirm_password"
                  labelText="Confirm Password"
                  type="password"
                  shrink
                  variant="outlined"
                  fullWidth={mdBelow ? true : false}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderSmsVerify = (setModalState) => {
    return (
      <>
        {/* {validatingOtp && ( */}
        <>
          {!infoUpdated ? (
            <Box px={4}>
              <Typography variant="body1">
                Please enter the 6 digits verification code we sent to your
                mobile +65*******390.
              </Typography>

              <Box py={5}>
                <OtpInput
                  value={otp}
                  onChange={(newValue) => {
                    setOtp(newValue);
                    setSmsVerify(false);
                  }}
                  numInputs={6}
                  isInputNum
                  shouldAutoFocus={true}
                  inputStyle={{
                    width: '3rem',
                    height: '3rem',
                    margin: '0 0.5rem',
                    borderRadius: 4,
                    border: '1px solid #E4E7F1',
                    fontWeight: 600,
                    fontSize: 22,
                  }}
                  focusStyle={{
                    border: '1px solid #5954E9',
                    outline: 'none',
                  }}
                />
              </Box>

              <Typography variant="body1" display="inline">
                Didn’t receive a code? Resend&nbsp;
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.fontWeight}
                >
                  (01:56s)
                </Typography>
              </Typography>
            </Box>
          ) : (
            <Box
              p={5}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Avatar
                style={{
                  width: '50px',
                  height: '50px',
                  backgroundColor: '#A21D23',
                }}
              >
                <CheckIcon fontSize="large" />
              </Avatar>
              <Box p={1} />
              <Typography variant="h2" className={classes.fontWeight}>
                Your Personal Info has been updated
              </Typography>
              <Box p={3} />
              <ButtonBase onClick={() => setModalState(false)}>
                <Typography variant="h5" color="primary">
                  Continue
                </Typography>
              </ButtonBase>
            </Box>
          )}
        </>
        {/* )} */}
      </>
    );
  };

  const renderPasswordUpdate = () => {
    return (
      <Box p={4}>
        <Box display="flex" alignItems="center" style={{ color: '#15B138' }}>
          <CheckCircleIcon fontSize="large" />
          <Box p={1} />
          <Typography variant="h2" className={classes.fontWeight}>
            Password upated!
          </Typography>
        </Box>
        <Box p={2} />
        <Typography variant="body1">
          Your password has been changed successfully. Use your new password to
          login
        </Typography>

        <Box
          p={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            size="large"
            color="secondary"
            style={{
              textTransform: 'initial',
              width: '15%',
            }}
            onClick={() => setChangePassword(!changePassword)}
          >
            Close
          </Button>
        </Box>
      </Box>
    );
  };

  const submitForm = async () => {
    if (stepformRef) {
      await stepformRef.validateForm();
      stepformRef.handleSubmit(); // go to xxxFormikOnSubmit
      return;
    }
  };

  const renderSecurityTab = () => {
    return (
      <Box style={{ border: '1px solid #ccc' }}>
        <ButtonModal
          isModalOpen={changeNumber}
          onCloseModal={() => setChangeNumber(false)}
          label={!smsVerify ? 'Change Phone Number' : 'SMS verify'}
          buttonLabel2={!smsVerify ? 'Next' : 'Confirm'}
          onPress1={() => {
            setChangeNumber(false);
          }}
          onPress2={() => {
            if (!smsVerify) {
              submitForm();
            } else {
              verifyOtp();
              UpdateSysInfoUser(user.user_id, {
                contact_number: phoneNumber.phone_number,
              });
              setPhoneNumber({
                ...phoneNumber,
                verified: true,
              });

              setChangeNumber(false);
            }
          }}
        >
          {!smsVerify && renderNumberForm()}
          {smsVerify && renderSmsVerify()}
        </ButtonModal>
        <ButtonModal
          isModalOpen={changeEmail}
          onCloseModal={() => setChangeEmail(false)}
          label={!smsVerify ? 'Change Email Address' : 'SMS verify'}
          buttonLabel2={!smsVerify ? 'Next' : 'Confirm'}
          onPress1={() => {
            setChangeEmail(false);
          }}
          onPress2={() => {
            if (!smsVerify) {
              submitForm();
            } else {
              UpdateSysInfoUser(user.user_id, {
                email: emailAddress.email_address,
              });
              setEmailAddress({
                ...emailAddress,
                verified: true,
              });
              setChangeEmail(false);
            }
          }}
        >
          {!smsVerify && renderEmailForm()}
          {smsVerify && renderSmsVerify()}
        </ButtonModal>
        <ButtonModal
          isModalOpen={changePassword}
          onCloseModal={() => setChangePassword(false)}
          label={'Change Password'}
          buttonLabel2="Next"
          onPress1={() => {
            setChangePassword(false);
          }}
          onPress2={() => {
            if (!passwordUpdate) {
              submitForm();
            } else {
              setChangePassword(false);
            }
          }}
          hideLabel={!passwordUpdate ? false : true}
          hideButtons={!passwordUpdate ? false : true}
          modalHeight={!passwordUpdate ? '50%' : '25%'}
        >
          {!passwordUpdate && renderPasswordForm()}
          {passwordUpdate && renderPasswordUpdate()}
        </ButtonModal>
        <IconActionList
          icon={<VpnKeyOutlinedIcon />}
          label="2-Steps login (SMS)"
          subLabel="Once enabled, you’ll be required to give two type of
              identification when you log into Meyzer360."
          switchChecked={smsSwitch}
          onSwitchChange={() => {
            UpdateSysInfoUser(user.user_id, {
              is_2fa_enabled: !smsSwitch,
            });
            setSmsSwitch(!smsSwitch);
          }}
          verified={smsSwitch}
          useSwitch
        />
        <Divider />
        <IconActionList
          icon={<PhoneOutlinedIcon />}
          label="Phone number"
          subLabel={
            phoneNumber.verified
              ? phoneNumber.phone_number
              : sysInfoUser.contact_number
          }
          verified={sysInfoUser.is_contact_verified > 0}
          onChangeClick={() => {
            setChangeNumber(!changeNumber);
            setSmsVerify(false);
          }}
        />
        <Divider />
        <IconActionList
          icon={<EmailOutlinedIcon />}
          label="Email Address"
          subLabel="In order to protect your account. "
          verified={sysInfoUser.is_email_verified > 0}
          onChangeClick={() => {
            setChangeEmail(!changeEmail);
            setSmsVerify(false);
          }}
        />
        <Divider />
        <IconActionList
          icon={<LockOutlinedIcon />}
          label="Password"
          subLabel="In order to protect your account. Your password is never shared."
          onChangeClick={() => {
            setChangePassword(true);
            setPasswordUpdate(false);
          }}
        />
      </Box>
    );
  };

  const renderNotificationsTab = () => {
    return <></>;
  };

  return (
    <ContentLayout
      header={
        <>
          <Typography variant="h2">Account</Typography>
          <BreadcrumbsNav
            pathList={[
              { path: 'Home', route: ROUTES.DASHBOARD },
              {
                path: 'Account',
                color: 'primary',
              },
            ]}
          />
        </>
      }
    >
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          borderRadius: '10px',
        }}
      >
        <Box className={classes.bgroot}>
          <Box pl={5} display="flex" alignItems="center">
            <AccountCircleOutlinedIcon fontSize="large" />
            <Box pl={1} display="flex" flexDirection="column">
              <Typography variant="h5">{user.email}</Typography>
              <Typography variant="body2">
                ID: {user.sysInfo_user_id}
              </Typography>
            </Box>
          </Box>
          <div
            className={classes.background}
            style={{
              backgroundImage: `url(${settingsbar})`,
            }}
          />
        </Box>

        <Tabs
          style={{
            borderBottom: '0.01rem solid #E4E4E4',
            paddingLeft: 40,
          }}
          indicatorColor="primary"
          textColor="primary"
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab label="Profile" className={classes.tab} />
          <Tab label="Security" className={classes.tab} />
          <Tab label="Notifications" className={classes.tab} />
        </Tabs>

        <Box p={5} pb={15}>
          {tabValue === 0 && renderProfileTab()}
          {tabValue === 1 && renderSecurityTab()}
          {tabValue === 2 && renderNotificationsTab()}
        </Box>
      </Paper>
    </ContentLayout>
  );
};

export default Settings;
