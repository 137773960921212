import { Box, Container } from '@material-ui/core';
import React from 'react';
import AuthLayout from 'src/components/AuthLayout';
import { CorpsecManageUser } from 'src/components/Corpsec';
import useBreakpoints from 'src/hooks/useBreakpoints';

const ManageUser = () => {
  const { mdBelow } = useBreakpoints();

  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box display="flex" height="calc(100% - 64px)" flexDirection="column">
        <Box
          display={mdBelow ? 'initial' : 'flex'}
          flexDirection="row"
          flexGrow={1}
        >
          <Container maxWidth="xl">
            <CorpsecManageUser />
          </Container>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ManageUser;
